import React, { useState } from 'react';

import ImpersonationModal from '../Modals/Impersonation';

interface ImpersonateProps {
  closeDropdown: () => void;
}

const Impersonate: React.FC<ImpersonateProps> = (props) => {
  const [showImpersonateModal, setShowImpersonateModal] = useState<boolean>(false);

  const show = (): void => {
    setShowImpersonateModal(true);
    props.closeDropdown();
  };

  return <>
    <hr className="h-0 my-0 border border-solid border-t-0 border-foreground opacity-25" />
    <li>
      <a
        className="
          dropdown-item
          text-sm
          py-2
          px-4
          font-normal
          block
          w-full
          whitespace-nowrap
          bg-transparent
          text-foreground
          hover:bg-background-light
        "
        href="#"
        onClick={show}
        >Impersonate</a>
    </li>
    <ImpersonationModal show={showImpersonateModal} close={() => { setShowImpersonateModal(false); }} />
  </>;
};

export default Impersonate;
