import React, { useMemo } from 'react';
import { Link, Outlet } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';

import SmartLink from '../../components/Library/SmartLink';
import LoadingSpinner from '../../components/LoadingSpinner';
import CenterLoadingSpinner from '../../components/LoadingSpinner/CenterLoadingSpinner';
import { useAccountContext } from '../../contexts/AccountContext';
import { useBrandContext } from '../../contexts/BrandContext';
import { useAPI } from '../../libs/libAPI';
import { getIconForService } from '../../libs/libIcon';
import { titleCase } from '../../libs/libString';
import useTitle from '../../libs/useTitle';
import { sortBy } from '../../libs/utils';
import WDProduct, { WDProductSchema } from '../../types/WDProduct';

const Apps: React.FC = () => {
  const api = useAPI();
  const accountContext = useAccountContext();
  const brandContext = useBrandContext();

  const availableApps = brandContext.brand.apps ?? [];

  const apps = useMemo(() => {
    return accountContext.currentAccountEntitlementComponents.filter((a) => !a.urlRequired || (a.urlRequired && a.url != null)).sort(sortBy('label'));
  }, [accountContext.currentAccountEntitlementComponents]);

  const productsQuery = useQuery(['products'], async () => {
    const response = await api.fetch(`${import.meta.env.VITE_API_URI}/v1/product-catalog/apps`, { method: 'GET' });

    const products = await WDProductSchema.array().parseAsync(await response.json());

    const apps = products.filter(p => p.showInCatalog).sort(sortBy('name')).sort(sortBy('category'));

    const filteredApps = availableApps.length === 0 ? apps : apps.filter((a) => availableApps.includes(a.name));

    const appsByCategory: { [key: string]: WDProduct[] } = filteredApps.reduce((r, a) => {
      if (!Object.keys(r).includes(a.category)) {
        r[a.category] = [];
      }
      r[a.category].push(a);
      return r;
    }, Object.create(null));

    return appsByCategory;
  });

  useTitle('Apps');

  if (productsQuery.data == null) return <CenterLoadingSpinner />;

  return <>
    <div className=''>
      <div className='max-w-[100vw] px-12 xl:px-0 3xl:max-w-screen-2xl 2xl:max-w-screen-xl xl:max-w-screen-lg mx-auto'>
        <div>
          <h2 className='text-2xl my-8'>My Apps</h2>
        </div>
        <div className='flex flex-wrap w-full'>
          {apps.map((tool) => {
            return (
              <div key={tool.label} className='w-full md:w-1/5 lg:w-1/7'>
                <SmartLink to={tool.url} target={'_blank'} targetExternal={true}>
                  <div className='mx-2 my-1 bg-background3 rounded-xl flex flex-col text-center py-4 shadow shadow-background3-darker hover:bg-hover hover:shadow-hover-darker'>
                    <div className='p-1 mx-auto'>
                      {getIconForService(tool.label, 'text-3xl', 'h-10 w-10')}
                    </div>
                    <div className='pt-2 mx-auto'>
                      {tool.label}
                    </div>
                  </div>
                </SmartLink>
              </div>
            );
          })}
          {accountContext.currentAccount == null && <>
            <CenterLoadingSpinner />
          </>}
        </div>
        <div className='mt-8'>
          <h2 className='text-2xl my-8'>All Services</h2>
        </div>
        <div className='flex flex-wrap w-full'>
          {Object.keys(productsQuery.data).map((category) => (
            <div key={category} className='w-full md:w-1/4 md:px-4'>
              <div className='mx-2 my-2 bg-hover rounded-t-xl py-4 px-4 text-center'>
                {titleCase(category)}
              </div>
              {productsQuery.data[category].map((app) => (
                <Link key={app.name} to={`/apps/${app.name}`}>
                  <div className={`mx-2 my-4 bg-background2 rounded-xl py-4 px-4 shadow shadow-background2-darker ${app.hasDatasheet ? 'border border-primary' : ''}`}>
                    <div className='flex'>
                      <div>
                        {getIconForService(app.name, 'text-3xl', 'h-8 w-8')}
                      </div>
                      <div className='pl-3 my-auto'>{app.name}</div>
                    </div>
                    <div className='pt-3 opacity-80 text-sm'>
                      {app.description}
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
    <Outlet />
  </>;
};

export default Apps;
