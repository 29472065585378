import React from 'react';
import toast from 'react-hot-toast/headless';

import DashboardWidget from '../../../components/DashboardWidget';
import Button from '../../../components/Library/Button';
import Select from '../../../components/Library/Select';
import { useBrandContext } from '../../../contexts/BrandContext';
import { ThemeName, THEMES } from '../../../data/themes';
import { useAPI } from '../../../libs/libAPI';
import useTitle from '../../../libs/useTitle';
import ThemeType from '../../../types/ThemeType';
import WDDashboardWidget, {
  WDBarChartWidgetConfiguration, WDWidgetType
} from '../../../types/WDDashboardWidget';

const Dashboard: React.FC = () => {
  const brandContext = useBrandContext();
  const api = useAPI();

  useTitle('Dashboard');

  const doFetch = async (): Promise<void> => {
    const result = await api.fetch(`${import.meta.env.VITE_API_URI}/v1/test/5`, { method: 'GET' });
    const resultObj = await result.json();
    console.log(resultObj);
  };

  const onThemeChange = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    brandContext.setTheme(e.target.value as ThemeName);
  };

  const onThemeTypeChange = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    brandContext.setThemeType(ThemeType[e.target.value as keyof typeof ThemeType]);
  };

  const chartData: WDBarChartWidgetConfiguration = {
    bars: [
      { dataKey: 'value' }
    ],
    xAxisLabelKey: 'label',
    useUniformBarColor: false
  };

  const widget: WDDashboardWidget = {
    label: 'Test Chart',
    type: WDWidgetType.Bar,
    configuration: chartData,
    data: [
      { label: 'test', value: 4 },
      { label: 'foo', value: 6 }
    ]
  };

  return <div className='m-3'>
    <h1>Dashboard</h1>
    <Button variant='danger' onClick={() => { toast.error(`test success ${new Date().toISOString()}`); }}>Lib Button</Button>
    <Button variant='success' onClick={() => { toast.success(`test success ${new Date().toISOString()}`); }}>Lib Button</Button>
    <Button variant='primary' onClick={() => { void doFetch(); }}>Lib Button</Button>
    <Button variant='primary' disabled onClick={() => alert('hi')}>Lib Button</Button>

    <Select onChange={onThemeChange}>
      {Object.keys(THEMES).map((t) => <option key={t} value={t}>{t}</option>)}
    </Select>
    <Select onChange={onThemeTypeChange}>
      <option value={'Dynamic'}>Dynamic</option>
      <option value={'Light'}>Light</option>
      <option value={'Dark'}>Dark</option>
    </Select>
    <div className='flex'>
      <DashboardWidget def={widget} />
      <DashboardWidget def={widget} />
      <DashboardWidget def={widget} />
      <DashboardWidget def={widget} />
    </div>
  </div>;
};

export default Dashboard;
