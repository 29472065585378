import { z } from 'zod';

export const WDGenericResponseSchema = z.object({
  statusCode: z.number().int(),
  message: z.string(),
  stackTrace: z.string().optional()
});

type WDGenericResponse = z.infer<typeof WDGenericResponseSchema>;

export default WDGenericResponse;
