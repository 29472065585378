import React from 'react';
import ReactDOM from 'react-dom';

interface PortalComponentProps {
  children: React.ReactNode;
  container: string | Element | DocumentFragment;
}

const PortalComponent: React.FC<PortalComponentProps> = (props: PortalComponentProps) => {
  const container = (typeof props.container === 'string' ? document.querySelector(props.container) : props.container);

  if (container === null) throw Error('Portal container can not be null');

  return ReactDOM.createPortal(props.children, container);
};

export default PortalComponent;
