import React, { useState } from 'react';

import { VisibilityState } from '@tanstack/react-table';

import Button from '../../Library/Button';
import Input from '../../Library/Input';
import { Modal, ModalBody, ModalFooter, ModalHeader } from '../../Library/Modal';
import PortalComponent from '../../PortalComponent';

interface ColumnVisibilityProps {
  columnDisplayNames: string[];
  currentVisibility: VisibilityState;
  defaultVisibility: VisibilityState;
  setVisibility: (state: VisibilityState) => void;
}

const ColumnVisibility: React.FC<ColumnVisibilityProps> = (props: ColumnVisibilityProps) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [internalState, setInternalState] = useState<VisibilityState>({ ...props.currentVisibility });

  const closeModal = (): void => {
    setShowModal(false);
  };

  const cancel = (): void => {
    setInternalState(props.currentVisibility);
    closeModal();
  };

  const saveChanges = (): void => {
    props.setVisibility({ ...internalState });
    closeModal();
  };

  const reset = (): void => {
    setInternalState({ ...props.defaultVisibility });
  };

  return <>
    <button className='whitespace-nowrap mr-3 px-2 rounded hover:bg-hover' onClick={() => setShowModal(true)}><i className='bi bi-columns-gap' /> Edit Columns</button>
    <PortalComponent container={'#edit-column-modal'}>
      <Modal show={showModal} closeModal={closeModal}>
        <ModalHeader closeModal={closeModal}>Edit Column Visibility</ModalHeader>
        <ModalBody className='overflow-y-auto md:max-h-[75vh]'>
          <table>
            <tbody>
              {Object.entries(internalState).map(([key, visible], idx) => {
                return (
                  <tr key={idx}>
                    <th className='text-right'>
                      {props.columnDisplayNames[idx]}
                    </th>
                    <td className='pl-2'>
                      <Input type={'checkbox'} checked={visible} onChange={(e) => { setInternalState((prevState) => { return { ...prevState, [key]: e.target.checked }; }); }} />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </ModalBody>
        <ModalFooter>
          <Button variant='primary' type='submit' onClick={saveChanges}>Save</Button>
          <Button variant='secondary' onClick={cancel}>Close</Button>
          <Button variant='danger' onClick={reset}>Reset</Button>
        </ModalFooter>
      </Modal>
    </PortalComponent>
  </>;
};

export default ColumnVisibility;
