import React from 'react';

interface SelectProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
}

const Select = React.forwardRef<HTMLSelectElement, SelectProps>((props, ref) => {
  const { className, ...otherProps } = props;
  return <select {...otherProps} className={`form-select appearance-none px-3 py-1.5 pr-7 text-base font-normal text-foreground bg-clip-padding bg-no-repeat border border-solid rounded transition ease-in-out focus:text-foreground focus:border-primary focus:outline-none ${otherProps.disabled === true ? 'bg-background-dark border-background-darker' : 'bg-background-light border-background-dark'} ${className ?? ''}`} ref={ref}>
    {props.children}
  </select>;
});

Select.displayName = 'Select';

export default Select;
