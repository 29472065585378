import React from 'react';

import Feedback from '../Feedback';
import Nav from './Nav';
import QuickNav from './QuickNav';

interface Props {
  children?: React.ReactNode;
};

const Layout: React.FC<Props> = ({ children }) => {
  return (
  <div className='min-h-screen bg-background'>
    <Nav />
    <div className='flex -h-screen-nav'>
      <QuickNav />
      <div className='-h-screen-nav text-foreground flex-1 overflow-y-auto shadow shadow-quicknav-darker' id='mainContent'>
        {children}
      </div>
    </div>
    <Feedback />
  </div>
  );
};

export default Layout;
