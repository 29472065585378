import React from 'react';
import { Link } from 'react-router-dom';

import { useAccountContext } from '../../contexts/AccountContext';
import { useUserContext } from '../../contexts/UserContext';

interface SmartLinkProps {
  to: string | null;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
  className?: string;
  children: React.ReactNode;
  target?: React.HTMLAttributeAnchorTarget;
  targetExternal?: boolean;
};

const httpRegex = /^https?:\/\//;

const SmartLink: React.FC<SmartLinkProps> = (props: SmartLinkProps) => {
  const accountContext = useAccountContext();
  const userContext = useUserContext();

  const { to, ...quickProps } = props;
  if (to === null || to.trim() === '') {
    return <>{props.children}</>;
  }

  let { targetExternal, ...linkProps } = quickProps;

  const formattedTo = to.replaceAll('{accountId}', accountContext.accountId).replaceAll('{email}', userContext.apparentUserEmail ?? '');

  if (httpRegex.test(to)) {
    return <a href={formattedTo} {...linkProps}>
      {props.children}
    </a>;
  }
  if (targetExternal === true && linkProps.target !== undefined) {
    linkProps = {
      ...linkProps,
      target: undefined
    };
  }
  return <Link {...linkProps} to={formattedTo} />;
};

export default SmartLink;
