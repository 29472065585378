import React from 'react';
import { ResponsiveContainer } from 'recharts';

import { DashboardWidgetProps } from './DashboardWidgetProps';

interface ChartWidgetProps extends DashboardWidgetProps {
  children: any; // TODO fix typing for this.
}

const ChartWidget: React.FC<ChartWidgetProps> = (props: ChartWidgetProps) => {
  return (
    <ResponsiveContainer height={275}>
      {props.children}
    </ResponsiveContainer>
  );
};

export default ChartWidget;
