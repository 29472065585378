import React from 'react';

import useTitle from '../../libs/useTitle';
import Preferences from './Preferences';
import Profile from './Profile';

const UserProfile: React.FC = () => {
  useTitle('User Profile');

  return (
    <div className='flex w-full mt-8'>
      <div className='w-full md:w-200 md:mx-auto'>
        <Profile />
        <Preferences />
      </div>
    </div>
  );
};

export default UserProfile;
