import React from 'react';

interface TableProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLTableElement>, HTMLTableElement> {

}

export const Table: React.FC<TableProps> = (props: TableProps) => {
  const { className, ...tableProps } = props;
  return <table {...tableProps} className={`${className ?? ''}`} />;
};

interface TableRowProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLTableRowElement>, HTMLTableRowElement> {

}

export const TableRow: React.FC<TableRowProps> = (props) => {
  const { className, ...tableProps } = props;
  return <tr {...tableProps} className={`border-b border-b-background3-dark ${className ?? ''}`} />;
};

export const TableDataRow: React.FC<TableRowProps> = (props) => {
  const { className, ...tableProps } = props;
  return <TableRow {...tableProps} className={`hover:bg-hover ${className ?? ''}`} />;
};
