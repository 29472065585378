import React from 'react';

interface TextAreaProps extends React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement> {

};

const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>((props, ref) => {
  const { className, ...otherProps } = props;
  return <textarea className={`block w-full px-3 py-1.5 text-base font-normal text-foreground bg-clip-padding border border-solid rounded transition ease-in-out m-0 focus:border-primary focus:outline-none ${otherProps.disabled === true ? 'bg-background-dark border-background-darker' : 'bg-background-light border-background-dark'} ${className ?? ''}`} {...otherProps} ref={ref} />;
});

TextArea.displayName = 'TextArea';

export default TextArea;
