import React from 'react';

interface InputProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {

};

const Input = React.forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const { className, ...otherProps } = props;
  return <input className={`block w-full px-3 py-1.5 text-base font-normal text-foreground bg-clip-padding border border-solid rounded transition ease-in-out m-0 focus:border-primary focus:outline-none ${otherProps.disabled === true ? 'bg-background-dark border-background-darker' : 'bg-background-light border-background-dark'} ${className ?? ''}`} {...otherProps} ref={ref} />;
});

Input.displayName = 'Input';

export default Input;
