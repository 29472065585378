import React, { useState } from 'react';

import EmbededFrame from '../../components/EmbededFrame';
import { cleanCaseActivityHtml } from '../../libs/libData';
import { formatToLocalTime } from '../../libs/libDate';
import WDCaseLogEntry from '../../types/WDCaseLogEntry';

interface CaseActivityProps {
  activity: WDCaseLogEntry;
}

const CaseActivity: React.FC<CaseActivityProps> = (props: CaseActivityProps) => {
  const { activity } = props;
  const [expanded, setExpanded] = useState(false);
  return <>
    <div className='max-w-full border border-background-darker mb-3'>
      <div className='py-2 px-1'>
        <p className='opacity-50'>{formatToLocalTime(activity.modifiedAt)}</p>
        <p>
          <span className='font-bold'>From: {activity.fromName}</span>
        </p>
        <div className='max-w-full overflow-hidden whitespace-nowrap overflow-ellipsis'>{activity.subject}</div>
        {expanded && <div><EmbededFrame htmlContent={cleanCaseActivityHtml(activity.htmlDescription)} className='w-full h-full test-1' /></div>}
        {!expanded && <div className='max-w-full overflow-hidden whitespace-nowrap overflow-ellipsis text-sm'>{activity.description}</div>}
      </div>
      <a onClick={() => setExpanded(!expanded)} className='cursor-pointer'>
        <div className='w-full hover:bg-hover py-1 px-2 flex'>
          <div className='opacity-50'>{expanded ? 'Show Less' : 'Show More'}</div>
          <div className='ml-auto opacity-50'><i className={`bi ${expanded ? 'bi-caret-up-square' : 'bi-caret-down-square'}`} /></div>
        </div>
      </a>
    </div>
  </>;
};

export default CaseActivity;
