import React from 'react';
import { Navigate } from 'react-router-dom';

import { localObjectStorage } from '../../libs/ObjectStorage';

export const RETURN_URL = 'redirect_uri';

const Callback: React.FC = () => {
  if (localObjectStorage.exists(RETURN_URL)) {
    const returnURI = localObjectStorage.get<string>(RETURN_URL);
    // localObjectStorage.remove(RETURN_URL);

    console.log('Redirect:', returnURI);

    if (!returnURI.includes('callback')) {
      return <Navigate to={returnURI} />;
    }
  }

  return <Navigate to={'/'} />;
};

export default Callback;
