/* eslint quote-props: "off" */
import React, { useEffect, useRef } from 'react';
import { useAuth } from 'react-oidc-context';
import { useParams } from 'react-router-dom';

import CenterLoadingSpinner from '../../components/LoadingSpinner/CenterLoadingSpinner';
import { useUserContext } from '../../contexts/UserContext';
import embedSites from '../../data/embed.json';
import Loading from '../Loading';

interface EmbedParams {
  [name: string]: string | undefined;
  readonly site: string;
}

const siteMap: { [name: string]: string } = embedSites;

const Embed: React.FC = () => {
  const auth = useAuth();
  const userContext = useUserContext();
  const { site } = useParams<EmbedParams>();
  const iframeRef = useRef<HTMLIFrameElement | null>(null);

  const handleIframeAuth = async (): Promise<void> => {
    const requestBody = {
      grant_type: 'access_token',
      client_id: 'dcf7325a0ba746569ce4a3fcfe133816',
      access_token: await userContext.getApiToken(),
      scope: 'openid profile email'
    };
    const resposne = await fetch(`${import.meta.env.VITE_MA_URL}/oauth2/token`, {
      method: 'POST',
      body: JSON.stringify(requestBody),
      headers: {
        'Content-Type': 'application/json'
      }
    });

    const tokenResponse = await resposne.json();

    iframeRef.current?.contentWindow?.postMessage({
      token: tokenResponse.id_token
    });
  };

  useEffect(() => {
    if (site === 'getcybr') {
      iframeRef.current?.addEventListener('load', handleIframeAuth);
    }
    return () => {
      if (site === 'getcybr') {
        iframeRef.current?.removeEventListener('load', handleIframeAuth);
      }
    };
  }, [iframeRef]);

  if (site === undefined) throw Error('Missing Site for Embed');

  if (auth.user == null) {
    return <CenterLoadingSpinner />;
  }

  const url = siteMap[site].replaceAll('{portalToken}', auth.user.access_token).replace('{middleAuth}', import.meta.env.VITE_MA_URL);

  return <iframe src={url} ref={iframeRef} className='w-screen lg:w-[calc(100vw-64px)] -h-screen-nav' />;
};

export default Embed;
