import { useOutletContext } from 'react-router-dom';

import { LGDataPrintSortField } from '../../types/LGDataPrint';

export interface SystemOutletContext {
  columns?: string[];
  data: any[][] | null;
  sort?: LGDataPrintSortField[];
}

export const useSystemOutletContext = (): SystemOutletContext => {
  return useOutletContext<SystemOutletContext>();
};
