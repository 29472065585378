import React from 'react';

import { useAccountContext } from '../../contexts/AccountContext';
import LoadingSpinner from '../LoadingSpinner';

const AccountLoadingSpinner: React.FC = () => {
  const accountContext = useAccountContext();

  return (
    <>
      {accountContext.currentAccount == null && <>
        <div className='flex w-full pt-2 mx-auto my-auto'>
          <div className='mx-auto text-center'>
            <LoadingSpinner className='h-28 w-28' />
            <p>Loading...</p>
          </div>
        </div>
      </>}
    </>
  );
};

export default AccountLoadingSpinner;
