import React from 'react';

import { useQuery } from '@tanstack/react-query';

import DashboardWidget from '../../components/DashboardWidget';
import CenterLoadingSpinner from '../../components/LoadingSpinner/CenterLoadingSpinner';
import { useAccountContext } from '../../contexts/AccountContext';
import { useAPI } from '../../libs/libAPI';
import WDNodeDetails, { WDNodeDetailsSchema } from '../../types/WDNodeDetails';

interface DashboardChartProps {
  nodeId: number;
  nodeLabel: string;
};

const DashboardChart: React.FC<DashboardChartProps> = (props: DashboardChartProps) => {
  const api = useAPI();
  const accountContext = useAccountContext();

  const data = useQuery(['node', accountContext.accountId, props.nodeId], async () => {
    const response = await api.fetch(`${import.meta.env.VITE_API_URI}/v1/nodes/${props.nodeId}`, {
      method: 'GET'
    });
    const dataPrint: WDNodeDetails = await WDNodeDetailsSchema.parseAsync(await response.json());
    return dataPrint;
  }, {
    staleTime: 60 * 60 * 1000,
    cacheTime: 4 * 60 * 60 * 1000
  });

  return (
    <div>
      <h2 className='text-2xl'>{props.nodeLabel}</h2>
      <div className='md:flex'>
        {data.data == null
          ? <CenterLoadingSpinner />
          : data.data.charts.map((chart) => <DashboardWidget key={chart.label} def={chart} />)}
      </div>
    </div>
  );
};

export default DashboardChart;
