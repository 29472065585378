import { z } from 'zod';

import { LGSystemSchema } from './LGSystem';

export const WDNodeSchema = z.intersection(LGSystemSchema, z.object({
  label: z.string()
}));

type WDNode = z.infer<typeof WDNodeSchema>;

export default WDNode;
