import React from 'react';

import useTitle from '../../libs/useTitle';

const NoAccess: React.FC = () => {
  useTitle('Access Denied');

  return <>
  <div className='flex -h-screen-nav'>
      <div className='flex flex-col mx-auto my-auto'>
        <div className='mx-auto'>
          <svg className={'fill-foreground h-60'}>
            <use href={'/icons.svg#cybro-sitting'} />
          </svg>
        </div>
        <h1 className='text-xl md:text-4xl pb-4 text-center'>Well, this is embarrassing...</h1>
        <h2 className='text-lg md:text-xl'>You don&apos;t have access to this page. We are not sure how you got here.</h2>
        <h2 className='text-lg'>If you clicked a link to get here please let us know so we can get that fixed ASAP.</h2>
        <div className='md:h-40'></div>
      </div>
  </div>
  </>;
};

export default NoAccess;
