import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import { useUserContext } from '../../contexts/UserContext';
import { getDemoUserEmail } from '../../libs/libDemoUser';
import useOutsideClickHandler from '../../libs/useOutsideClickHandler';
import AppLauncher from '../AppLauncher';
import InitialAvatar from '../InitialAvatar';
import Account from './Account';
import DropdownLink from './DropdownLink';
import Impersonate from './Impersonate';
import NavLogo from './NavLogo';
import ThemeModePicker from './ThemeModePicker';

const Nav: React.FC = () => {
  const [showAppLauncher, setShowAppLauncher] = useState<boolean>(false);
  const [showUserDropdown, setShowUserDropdown] = useState<boolean>(false);
  const userContext = useUserContext();

  const userFullName = `${userContext.apparentUser.firstName} ${userContext.apparentUser.lastName}`;

  const userMenuRef = useRef<any>();
  useOutsideClickHandler(() => {
    setShowUserDropdown(false);
  }, userMenuRef);

  const exitImpersonate = (): void => {
    userContext.exitImpersonate();
    setShowUserDropdown(false);
  };

  const startDemoMode = (): void => {
    const userEmail = getDemoUserEmail();
    console.log(`Picked ${userEmail} to use for demo.`);
    void userContext.impersonate(userEmail);
    setShowUserDropdown(false);
  };

  return <>
    <div className={'min-w-screen w-full h-nav bg-nav text-nav-text flex flex-row z-50 border-b border-b-nav-darker'}>
      <a className={`w-16 h-nav inline-block text-3xl p-1 text-nav-text text-center hover:text-nav-text-dark hover:bg-nav-light/50 hover:border-b hover:border-b-1 hover:border-b-nav-dark ${showAppLauncher ? 'bg-nav-dark' : ''}`} onClick={() => setShowAppLauncher(!showAppLauncher)}>
        <i className='mdl2-2 mdl2-2-Waffle'></i>
      </a>
      <Link to='/' className='text-xl mx-auto sm:mx-2 my-auto '>
        <NavLogo />
      </Link>
      {import.meta.env.VITE_SHOW_ENV_BANNER === 'true' &&
        <div className={'bg-nav-light/50  py-3 pointer-events-none hidden md:block'}>
          <span className={'sm:mx-2 my-auto'}>
            {import.meta.env.VITE_ENV_BANNER_LABEL}
          </span>
        </div>}
      <div className='hidden md:flex'>
        <Account />
      </div>
      <div className='md:ml-auto h-nav mr-1 align-middle flex dropdown relative'>
        <span className='pr-2 text-md py-3 font-thin hidden md:inline'>{userContext.isImpersonating && <>{userContext.realUser.firstName} {userContext.realUser.lastName} as </>}</span>
        <span className='pr-2 text-md py-3 hidden md:inline'>{userFullName} <span className='font-thin'>@</span> {userContext.apparentUser.accountName}</span>
        <InitialAvatar name={userFullName} onClick={() => setShowUserDropdown(!showUserDropdown)} className='dropdown-toggle hover:cursor-pointer' />
        <ul ref={userMenuRef} className={`dropdown-menu min-w-max absolute bg-background text-foregroud z-50 float-right py-2 list-none text-left rounded-lg shadow-lg mt-12 mr-1 m-0 bg-clip-padding border-1 border border-foreground right-2 ${showUserDropdown ? 'block' : 'hidden'}`}>
          {userContext.isImpersonating && <>
            <li className='md:hidden'>
              <span className='dropdown-item text-sm py-2 px-4 block w-full whitespace-nowrap bg-transparent text-foreground hover:bg-light'>{userContext.realUser.firstName} as</span>
            </li>
          </>}
          <li className='md:hidden'>
            <span className='dropdown-item text-sm py-2 px-4 font-bold block w-full whitespace-nowrap bg-transparent text-foreground hover:bg-light'>{userFullName}</span>
          </li>
          <hr className="h-0 my-0 border border-solid border-t-0 border-foreground opacity-25 md:hidden" />
          <li>
            <DropdownLink to='/profile' closeDropdown={() => setShowUserDropdown(false)}>Profile</DropdownLink>
          </li>
          {((userContext.authUser.whitedog_app_metadata.user_info.account.is_service_provider ?? false) === true && !userContext.isImpersonating) && <>
            <li>
              <a
                className="
                  dropdown-item
                  text-sm
                  py-2
                  px-4
                  font-normal
                  block
                  w-full
                  whitespace-nowrap
                  bg-transparent
                  text-foreground
                  hover:bg-background-light
                "
                href="#"
                onClick={startDemoMode}
                >Demo Mode</a>
            </li>
          </>}
          {(userContext.authUser.whitedog_app_metadata.user_info.gimp ?? false) === true && <Impersonate closeDropdown={() => setShowUserDropdown(false)}/>}
          {userContext.isImpersonating && userContext.realUser.portalRole !== 'Sales' && <>
            <li>
              <a
                className="
                  dropdown-item
                  text-sm
                  py-2
                  px-4
                  font-normal
                  block
                  w-full
                  whitespace-nowrap
                  bg-transparent
                  text-foreground
                  hover:bg-background-light
                "
                href="#"
                onClick={exitImpersonate}
                >{(userContext.authUser.whitedog_app_metadata.user_info.gimp ?? false) === true ? 'Return To Self' : 'Exit Demo'}</a>
            </li>
          </>}
          <hr className="h-0 my-0 border border-solid border-t-0 border-foreground opacity-25" />
          <ThemeModePicker />
          <hr className="h-0 my-0 border border-solid border-t-0 border-foreground opacity-25" />
          <li>
            <a
              className="
                dropdown-item
                text-sm
                py-2
                px-4
                font-normal
                block
                w-full
                whitespace-nowrap
                bg-transparent
                text-foreground
                hover:bg-background-light
              "
              href="#"
              onClick={userContext.logout}
              >Logout</a>
          </li>
        </ul>
      </div>
    </div>
    <div className={`transition-all ease-in-out duration-250 absolute w-screen md:w-80 p-3 bg-background text-foreground border-r-2 border-background-dark top-12 -h-screen-nav z-40 ${showAppLauncher ? 'left-0' : '-left-screen md:-left-80'}`}>
      <AppLauncher showAppLauncher={showAppLauncher} closeAppLauncher={() => setShowAppLauncher(false)}/>
    </div>
    <div className={`transition-all ease-in-out duration-250 absolute hidden md:block w-screen -h-screen-nav ${showAppLauncher ? 'z-10 bg-black/40' : '-z-10 bg-transparent'}`} onClick={() => setShowAppLauncher(false)} />
  </>;
};

export default Nav;
