import React from 'react';

import LoadingSpinner from './';

const CenterLoadingSpinner: React.FC = () => {
  return <>
    <div className='flex w-full pt-2'>
      <div className='mx-auto text-center'>
        <LoadingSpinner className='h-28 w-28' />
        <p>Loading...</p>
      </div>
    </div>
  </>;
};

export default CenterLoadingSpinner;
