import { z } from 'zod';

import { WDToolSchema } from './WDTool';

export const WDProductSchema = z.object({
  name: z.string(),
  showInCatalog: z.boolean(),
  description: z.string(),
  category: z.string(),
  isActive: z.boolean(),
  hasDatasheet: z.boolean(),
  tools: z.array(WDToolSchema)
});

type WDProduct = z.infer<typeof WDProductSchema>;

export default WDProduct;
