import React, { useEffect, useRef } from 'react';

import { useBrandContext } from '../../contexts/BrandContext';
import ThemeType from '../../types/ThemeType';

interface EmbededFrameProps extends React.IframeHTMLAttributes<HTMLIFrameElement> {
  htmlContent: string;
}

const IMAGE_FIX_CSS = 'img { filter: invert(1); color: #fff; }';
const DARK_IMAGE_FIX_CSS = `<style>${IMAGE_FIX_CSS}</style>`;
const DYNAMIC_IMAGE_FIX_CSS = `<style>@media (prefers-color-scheme: dark) { ${IMAGE_FIX_CSS} }</style>`;

const EmbededFrame: React.FC<EmbededFrameProps> = (props) => {
  const { themeType } = useBrandContext();

  const { htmlContent, className, ...otherProps } = props;

  const ref = useRef<any>();

  useEffect(() => {
    const iframeLoad = (): void => {
      const imgElements: HTMLImageElement[] = ref.current?.contentDocument?.getElementsByTagName('img');
      if (imgElements != null) {
        Array.from(imgElements).forEach((element: HTMLImageElement) => {
          if (element.alt == null || element.alt.trim() === '') {
            element.alt = 'Image Not Rendered';
          }
        });
      }

      if (ref.current != null) {
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions, @typescript-eslint/restrict-plus-operands
        ref.current.style.height = `${(ref.current.contentDocument?.body.scrollHeight ?? 100) + 25}px`;
      }
    };

    ref.current?.addEventListener('load', iframeLoad);

    return () => {
      ref.current?.removeEventListener('load', iframeLoad);
    };
  }, [ref]);

  const htmlSrc = `${htmlContent}${themeType === ThemeType.Dark ? DARK_IMAGE_FIX_CSS : ''}${themeType === ThemeType.Dynamic ? DYNAMIC_IMAGE_FIX_CSS : ''}`;

  return <iframe srcDoc={htmlSrc} {...otherProps} className={`${themeType === ThemeType.Dynamic ? 'dark:invert' : ''} ${themeType === ThemeType.Dark ? 'invert' : ''} ${className ?? ''}`} ref={ref} />;
};

export default EmbededFrame;
