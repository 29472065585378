import './styles/main.scss';

import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createRoutesFromChildren, matchRoutes, useLocation, useNavigationType
} from 'react-router';

import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import App from './App';
import { ErrorBoundry } from './components/ErrorBoundry';
import { BrandContextProvider } from './contexts/BrandContext';
import Critical from './pages/Error/Critical';

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [new BrowserTracing({
    routingInstrumentation: Sentry.reactRouterV6Instrumentation(
      React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes
    ),
    tracePropagationTargets: [
      'wdsvc.io'
    ]
  }),
  new Sentry.Replay()
  ],
  environment: import.meta.env.VITE_ENV_BANNER_LABEL,
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0

});

const queryClient = new QueryClient();

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ErrorBoundry key={window.location.host}>
        <ErrorBoundry.OK>
          <BrandContextProvider brandRef={window.location.hostname}>
            <App />
          </BrandContextProvider>
        </ErrorBoundry.OK>
        <ErrorBoundry.Error component={Critical} />
      </ErrorBoundry>
      <ReactQueryDevtools initialIsOpen={false} position='bottom-right' />
    </QueryClientProvider>
  </React.StrictMode>
);
