import React from 'react';

import { useThemeModePreference } from '../../libs/usePreference';
import ThemeType from '../../types/ThemeType';

const ThemeModePicker: React.FC = () => {
  const [prefThemeType, setThemeType] = useThemeModePreference();

  const getThemeSetter = (theme: number): (event: React.MouseEvent) => void => {
    return (event: React.MouseEvent) => {
      event.preventDefault();

      setThemeType(theme);
    };
  };

  return <>
    {['Dynamic', 'Light', 'Dark'].map((themeType, idx) => {
      return (
        <li key={themeType}>
          <a
            className={`
              dropdown-item
              text-sm
              py-2
              px-4
              font-normal
              block
              w-full
              whitespace-nowrap
              bg-transparent
              text-foreground
              ${prefThemeType === (idx as ThemeType) ? 'underline' : ''}
              hover:bg-hover
            `}
            href=""
            onClick={getThemeSetter(idx)}
            >{themeType}</a>
        </li>
      );
    })}
  </>;
};

export default ThemeModePicker;
