import React, { useEffect, useState } from 'react';

import Input from './Input';

interface DebouncedInputProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  value: string;
  onChange: (value: string) => void;
  debounce?: number;
}

const DebouncedInput: React.FC<DebouncedInputProps> = (props: DebouncedInputProps) => {
  const { value: initialValue, onChange, debounce, ...inputProps } = props;

  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      console.debug('Setting value to: ', value);
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [value]);

  return <Input {...inputProps} value={value} onChange={(e) => setValue(e.target.value)} />;
};

export default DebouncedInput;
