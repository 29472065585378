import { useCallback, useMemo } from 'react';

import { PaginationState } from '@tanstack/react-table';

import { useParentQuery } from './useParentQuery';

export const usePaginationState = (pageSize: number = 15): [PaginationState, (state: PaginationState | ((old: PaginationState) => PaginationState)) => void] => {
  const [queryParams, setQueryParams] = useParentQuery();

  const state = useMemo(() => {
    const p: PaginationState = {
      pageIndex: Number(queryParams.get('p') ?? '0'),
      pageSize: Number(queryParams.get('pSize') ?? pageSize.toString())
    };
    return p;
  }, [queryParams]);

  const setState = useCallback((value: PaginationState | ((old: PaginationState) => PaginationState)) => {
    setQueryParams((prev) => {
      const output = new URLSearchParams(prev);
      const newValue = typeof value === 'function' ? value(state) : value;
      output.set('p', newValue.pageIndex.toString());
      output.set('pSize', newValue.pageSize.toString());
      return output;
    });
  }, [setQueryParams, state]);

  return [state, setState];
};
