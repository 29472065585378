import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import { useUserContext } from '../../contexts/UserContext';
import { useNavItems } from './navItems';

const QuickNav: React.FC = () => {
  const location = useLocation();
  const userContext = useUserContext();
  const navItems = useNavItems();

  return <>
    <div className='hidden md:flex md:flex-col w-16 bg-background3 text-foreground -h-screen-nav'>
      <div className='h-0 shadow shadow-nav-darker'></div>
      {navItems.map((navItem) => {
        const selected = navItem.exact === true ? (location.pathname === navItem.path) : (location.pathname.includes(navItem.path ?? 'null'));
        const authed = navItem.auth == null ? true : navItem.auth(userContext);
        if (!authed) {
          return <React.Fragment key={navItem.name} />;
        }

        if (navItem.disabled !== true) {
          if (navItem.path != null) {
            return (
              <Link to={navItem.path} key={navItem.name} className={navItem.className}>
                <div className={`${selected ? 'border-l-quickNav border-l-4' : 'pl-1'} py-2 hover:bg-background hover:shadow hover:shadow-background2 hover:text-quickNavHover`}>
                  <div className={`text-center ${selected ? 'text-quickNav' : ''}`}><i className={`bi ${navItem.icon}${selected ? '-fill' : ''} text-2xl`} /></div>
                  <div className='text-center text-xs text-foreground'>{navItem.name}</div>
                </div>
              </Link>
            );
          } else if (navItem.to != null) {
            return (
              <a href={navItem.to} key={navItem.name} className={navItem.className}>
                <div className={`${selected ? 'border-l-quickNav border-l-4' : 'pl-1'} py-2 hover:bg-background hover:shadow hover:shadow-background2 hover:text-quickNavHover`}>
                  <div className={`text-center ${selected ? 'text-quickNav' : ''}`}><i className={`bi ${navItem.icon}${selected ? '-fill' : ''} text-2xl`} /></div>
                  <div className='text-center text-xs text-foreground'>{navItem.name}</div>
                </div>
              </a>
            );
          }
        } else {
          return (
            <div key={navItem.name} className={`${selected ? 'border-l-quickNav border-l-4' : 'pl-1 opacity-60'} py-2 `}>
              <div className={`text-center ${selected ? 'text-quickNav' : ''}`}><i className={`bi ${navItem.icon}${selected ? '-fill' : ''} text-2xl`} /></div>
              <div className='text-center text-xs text-foreground'>{navItem.name}</div>
            </div>
          );
        }
        return <div key={navItem.name} className={navItem.className}></div>;
      })}
    </div>
  </>;
};

export default QuickNav;
