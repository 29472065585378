import React, { useEffect } from 'react';

import { useUserContext } from '../../contexts/UserContext';
import { getDemoUserEmail } from '../../libs/libDemoUser';

const SalesDemo: React.FC = () => {
  const userContext = useUserContext();

  const startDemoMode = (): void => {
    const userEmail = getDemoUserEmail();
    console.log(`Picked ${userEmail} to use for demo.`);
    void userContext.impersonate(userEmail);
  };

  useEffect(() => {
    if (userContext.apparentUser.portalRole === 'Sales') {
      startDemoMode();
    }
  }, [userContext.apparentUser]);

  return <></>;
};

export default SalesDemo;
