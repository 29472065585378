import { formatInTimeZone, utcToZonedTime } from 'date-fns-tz';

export const formatToLocalTime = (time: string | Date, format: string = 'PPpp'): string => {
  return formatInTimeZone(time, Intl.DateTimeFormat().resolvedOptions().timeZone, format);
};

export const toLocalTime = (time: string | Date): Date => {
  return utcToZonedTime(time, Intl.DateTimeFormat().resolvedOptions().timeZone);
};

export interface DateFormats {
  [key: string]: DateFormat[];
}
interface DateFormat { ceiling: number | null; text: string }

export type TimeUnit = [number, string];

export const humanizedTimeSpan = (date: Date | string, refDate?: Date | string, customDateFormats?: DateFormats, customTimeUnits?: TimeUnit[]): string | null => {
  // Date Formats must be be ordered smallest -> largest and must end in a format with ceiling of null
  const dateFormats: DateFormats = customDateFormats ?? {
    past: [
      { ceiling: 60, text: '$seconds seconds ago' },
      { ceiling: 3600, text: '$minutes minutes ago' },
      { ceiling: 86400, text: '$hours hours ago' },
      { ceiling: 2629744, text: '$days days ago' },
      { ceiling: 31556926, text: '$months months ago' },
      { ceiling: null, text: '$years years ago' }
    ],
    future: [
      { ceiling: 60, text: 'in $seconds seconds' },
      { ceiling: 3600, text: 'in $minutes minutes' },
      { ceiling: 86400, text: 'in $hours hours' },
      { ceiling: 2629744, text: 'in $days days' },
      { ceiling: 31556926, text: 'in $months months' },
      { ceiling: null, text: 'in $years years' }
    ]
  };
  // Time units must be be ordered largest -> smallest
  const timeUnits: TimeUnit[] = customTimeUnits ?? [
    [31556926, 'years'],
    [2629744, 'months'],
    [86400, 'days'],
    [3600, 'hours'],
    [60, 'minutes'],
    [1, 'seconds']
  ];

  date = new Date(date);
  refDate = refDate != null ? new Date(refDate) : new Date();
  let secondsDifference = (refDate.getTime() - date.getTime()) / 1000;

  if (secondsDifference === 0) {
    return 'now';
  }

  let tense = 'past';
  if (secondsDifference < 0) {
    tense = 'future';
    secondsDifference = 0 - secondsDifference;
  }

  function getFormat(): DateFormat | null {
    for (let i = 0; i < dateFormats[tense].length; i++) {
      const ceiling = dateFormats[tense][i].ceiling;
      if (ceiling == null || secondsDifference <= ceiling) {
        return dateFormats[tense][i];
      }
    }
    return null;
  }

  function getTimeBreakdown(): { [key: string]: number } {
    let seconds = secondsDifference;
    const breakdown: { [key: string]: number } = {};
    for (let i = 0; i < timeUnits.length; i++) {
      const occurencesOfUnit = Math.floor(seconds / timeUnits[i][0]);
      seconds = seconds - (timeUnits[i][0] * occurencesOfUnit);
      breakdown[timeUnits[i][1]] = occurencesOfUnit;
    }
    return breakdown;
  }

  function renderDate(dateFormat: DateFormat | null): string | null {
    if (dateFormat == null) return null;
    const breakdown = getTimeBreakdown();
    const timeAgoText = dateFormat.text.replace(/\$(\w+)/g, (substring, ...args) => {
      return breakdown[args[0]].toString();
    });
    return depluralizeTimeAgoText(timeAgoText, breakdown);
  }

  function depluralizeTimeAgoText(timeAgoText: string, breakdown: { [key: string]: number }): string {
    for (const i in breakdown) {
      if (breakdown[i] === 1) {
        const regexp = new RegExp('\\b' + i + '\\b');
        timeAgoText = timeAgoText.replace(regexp, function() {
          return arguments[0].replace(/s\b/g, '');
        });
      }
    }
    return timeAgoText;
  }

  return renderDate(getFormat());
};
