/* eslint quote-props: "off" */
import React, { useEffect, useRef, useState } from 'react';
import { useAuth } from 'react-oidc-context';

import CenterLoadingSpinner from '../../components/LoadingSpinner/CenterLoadingSpinner';
import { useUserContext } from '../../contexts/UserContext';

const targetOrigin = 'https://app.getcybr.com';

const GetCyberEmbed: React.FC = () => {
  const auth = useAuth();
  const userContext = useUserContext();
  const [appReady, setAppReady] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);
  const [authData, setAuthData] = useState<any>(null);
  const iframeRef = useRef<HTMLIFrameElement | null>(null);

  const handleIframeAuth = async (): Promise<void> => {
    if (iframeRef.current?.contentWindow == null) {
      console.log('iframe not ready');
      return;
    }

    let data = authData;

    if (data == null) {
      const requestBody = {
        grant_type: 'access_token',
        client_id: 'dcf7325a0ba746569ce4a3fcfe133816',
        access_token: await userContext.getApiToken(),
        scope: 'openid profile email'
      };

      const resposne = await fetch(`${import.meta.env.VITE_MA_URL}/oauth2/token`, {
        method: 'POST',
        body: JSON.stringify(requestBody),
        headers: {
          'Content-Type': 'application/json'
        }
      });

      data = await resposne.json();

      setAuthData(data);

      console.log('GetCyberToken', data);
    }

    iframeRef.current?.contentWindow?.postMessage(data, targetOrigin);
  };

  const handleMessage = (event: MessageEvent<any>): void => {
    if (event.origin === targetOrigin) {
      console.log(event.data);
      if (event.data === 'flutter_app_ready') {
        setAppReady(true);
        void handleIframeAuth();
      } else if (event.data === 'received') {
        setAuthenticated(true);
      }
    }
  };

  useEffect(() => {
    const intervalHandle = setInterval(() => {
      if (!appReady) {
        console.log('Checking if Flutter app is ready...');
        iframeRef.current?.contentWindow?.postMessage('are_you_ready', targetOrigin);
      }
      if (appReady && !authenticated) {
        void handleIframeAuth();
      }
    }, 500);

    window.addEventListener('message', handleMessage);

    return () => {
      clearInterval(intervalHandle);
      window.removeEventListener('message', handleMessage);
    };
  }, [iframeRef, appReady, authenticated, authData]);

  if (auth.user == null) {
    return <CenterLoadingSpinner />;
  }

  return <iframe src={import.meta.env.VITE_GETCYBER_URL} ref={iframeRef} className='w-screen lg:w-[calc(100vw-64px)] -h-screen-nav' />;
};

export default GetCyberEmbed;
