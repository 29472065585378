import { useEffect, useState } from 'react';

import { useBrandContext } from '../contexts/BrandContext';
import { PREFERENCES, PrefName, useUserPreferences } from '../contexts/UserPreferenceContext';
import ThemeType from '../types/ThemeType';

export const usePreferenceState = <T>(preference: string, defaultValue: T): [T, (value: T) => void] => {
  const userPreferences = useUserPreferences();

  const [state, setState] = useState<T>(userPreferences.preferences[preference] ?? defaultValue);

  return [
    state,
    (value) => {
      setState(value);
      userPreferences.setPreference(preference, value);
    }
  ];
};

const usePreference = <T>(preference: PrefName): [T, (value: T) => void] => {
  const userPreferences = useUserPreferences();

  const key = PREFERENCES[preference].key;
  const defaultValue = PREFERENCES[preference].defaultValue;

  const [state, setState] = useState<T>(userPreferences.preferences[key] ?? defaultValue);

  return [
    state,
    (value) => {
      setState(value);
      userPreferences.setPreference(PREFERENCES[preference].key, value);
    }
  ];
};

export const useThemeModePreference = (): [ThemeType, (value: ThemeType) => void] => {
  const brandContext = useBrandContext();
  const [mode, setMode] = usePreference<ThemeType>('ThemeMode');

  useEffect(() => {
    brandContext.setThemeType(mode);
  }, [mode]);

  return [mode, setMode];
};

export default usePreference;
