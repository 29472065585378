import React from 'react';
import { Link } from 'react-router-dom';
import { z } from 'zod';

import { useQuery } from '@tanstack/react-query';

import {
  DualPaneLayout, LeftPane, LeftPaneHeader, RightPane
} from '../../components/DualPaneLayout';
import LoadingSpinner from '../../components/LoadingSpinner';
import { useAccountContext } from '../../contexts/AccountContext';
import { useAPI } from '../../libs/libAPI';
import useTitle from '../../libs/useTitle';
import { sortBy } from '../../libs/utils';
import { WDNodeSchema } from '../../types/WDNode';

const Nodes: React.FC = () => {
  const api = useAPI();
  const accountContext = useAccountContext();

  const nodeQuery = useQuery(['nodes', accountContext.accountId], async () => {
    const response = await api.fetch(`${import.meta.env.VITE_API_URI}/v1/nodes`, {
      method: 'GET'
    });

    const nodes = await z.array(WDNodeSchema).parseAsync(await response.json());

    return nodes;
  });

  useTitle('Nodes');

  return <>
    <DualPaneLayout>
      <LeftPane>
        <LeftPaneHeader title='Nodes' />
        <div>
          {nodeQuery.data?.sort(sortBy('label')).map((node) =>
            <Link key={node.id} to={`/nodes/${node.id}`} title={`${node.label}`}>
              <div className={'w-full rounded-md p-2 hover:bg-hover overflow-hidden overflow-ellipsis whitespace-nowrap'}>
                {node.label}
              </div>
            </Link>
          )}
          {(nodeQuery.data?.length ?? -1) === 0 && <>
            <div className={'w-full rounded-md p-2 overflow-hidden overflow-ellipsis whitespace-nowrap'}>
              No Node Sources Found
            </div>
          </>}
          {nodeQuery.isLoading && <>
            <div className={'w-full rounded-md p-2 overflow-hidden overflow-ellipsis whitespace-nowrap'}>
              Loading...
            </div>
          </>}
        </div>
      </LeftPane>
      <RightPane>
        {accountContext.currentAccount == null && <>
          <div className='flex flex-col mx-auto my-auto pt-12'>
            <LoadingSpinner className='mx-auto w-32 h-32' />
            <div className='text-center h2 pt-2'>Loading...</div>
          </div>
        </>}
      </RightPane>
    </DualPaneLayout>
  </>;
};

export default Nodes;
