import React, { useState } from 'react';

import FeedbackModal from '../Modals/Feedback';

const Feedback: React.FC = () => {
  const [modalShow, setModalShow] = useState<boolean>(false);

  return <>
    <div className='hidden lg:block fixed bottom-0 right-20'>
      <div className='inline-block h-5 text-hover text-xs pt-2 px-2 cursor-default'>
        <span>Powered By WhiteDog Cyber</span>
      </div>
      <div className='inline-block h-9 bg-nav text-nav-text pt-2 px-2 cursor-pointer select-none' onClick={() => setModalShow(true)}>
        <span><i className="bi bi-chat-left-fill" /> Feedback</span>
      </div>
    </div>
    <FeedbackModal show={modalShow} close={() => setModalShow(false)} />
  </>;
};

export default Feedback;
