import { NavigateOptions, URLSearchParamsInit, useSearchParams } from 'react-router-dom';

export declare type SetURLSearchParams = (nextInit?: URLSearchParamsInit | ((prev: URLSearchParams) => URLSearchParamsInit), navigateOpts?: NavigateOptions) => void;

export const useParentQuery = (): [URLSearchParams, SetURLSearchParams] => {
  const [searchParams, setSearchParams] = useSearchParams();
  if (searchParams.has('parentQuery')) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const decodedQuery = decodeURIComponent(searchParams.get('parentQuery')!);

    const parentQuery = new URLSearchParams(decodedQuery);
    const setParentQuery: SetURLSearchParams = (nextInit?, navigateOpts?) => {
      if (nextInit == null) return;
      let init: URLSearchParamsInit;
      if (typeof nextInit === 'function') {
        init = nextInit(parentQuery);
      } else {
        init = nextInit;
      }

      setSearchParams((prev) => {
        const output = new URLSearchParams(prev);
        // eslint-disable-next-line @typescript-eslint/no-base-to-string
        output.set('parentQuery', `?${init.toString()}`);
        return output;
      });
    };

    return [parentQuery, setParentQuery];
  }
  return [searchParams, setSearchParams];
};
