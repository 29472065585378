import React from 'react';

import { useBrandContext } from '../../contexts/BrandContext';
import ALApp from '../../types/ALApp';
import SmartLink from '../Library/SmartLink';

interface AppLauncherAppProps {
  closeAppLauncher: () => void;
  app: ALApp;
}

const AppLauncherApp: React.FC<AppLauncherAppProps> = (props: AppLauncherAppProps) => {
  const brandContext = useBrandContext();

  const iconColor = brandContext.theme.iconColor ?? 'nav';

  if (props.app.path === '') {
    return <div className="basis-1/2 py-1 h-12">
      <div className='py-1 px-2 rounded-md flex flex-row'>
        <div className={`w-10 h-10 p-1 rounded-md bg-${iconColor} text-${iconColor}-text text-center`}>{props.app.icon}</div>
        <span className='inline-block text-md align-middle pl-1 text-bold my-auto'>{props.app.name}</span>
      </div>
    </div>;
  } else {
    return <div className="basis-1/2 py-1">
      <SmartLink to={props.app.path} onClick={props.closeAppLauncher} className='align-middle inline-block h-10' target={'_blank'} targetExternal={true}>
        <div className='hover:bg-background-light py-1 px-2 rounded-md hover:border-1 border-foreground flex flex-row'>
          <div className={`w-10 h-10 p-1 rounded-md bg-${iconColor} text-${iconColor}-text text-center`}>{props.app.icon}</div>
          <span className='inline-block text-md align-middle pl-1 text-bold my-auto'>{props.app.name}</span>
        </div>
      </SmartLink>
    </div>;
  }
};

export default AppLauncherApp;
