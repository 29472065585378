import { RefObject, useEffect } from 'react';

const useOutsideClickHandler = <T extends HTMLElement,>(handler: () => void, ref: RefObject<T>): void => {
  const insideHandler = (event: MouseEvent): void => {
    if (ref.current !== null && !ref.current.contains(event.target as Node)) {
      handler();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', insideHandler);

    return () => {
      document.removeEventListener('mousedown', insideHandler);
    };
  }, [ref]);
};

export default useOutsideClickHandler;
