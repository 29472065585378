import { z } from 'zod';

import {
  CaseCategory, CaseOrigin, CasePriority, CaseSeverity, CaseState, CaseStatus, CaseType
} from './CaseEnums';

export const WDCaseSchema = z.object({
  id: z.string(),
  ticketNumber: z.string(),
  createdByName: z.string().nullable(),
  createdById: z.string().nullable(),
  title: z.string().nullable(),
  caseType: z.nativeEnum(CaseType),
  category: z.nativeEnum(CaseCategory),
  // incidentSeverity: z.string().nullable(),
  severity: z.nativeEnum(CaseSeverity),
  priority: z.nativeEnum(CasePriority),
  origin: z.nativeEnum(CaseOrigin).nullable(),
  state: z.nativeEnum(CaseState),
  status: z.nativeEnum(CaseStatus),
  createdAt: z.string(),
  modifiedAt: z.string()
});

type WDCase = z.infer<typeof WDCaseSchema>;

export default WDCase;
