import React from 'react';
import { Link, LinkProps } from 'react-router-dom';

interface DropdownLinkProps extends LinkProps {
  closeDropdown: () => void;
};

const DropdownLink: React.FC<DropdownLinkProps> = (props: DropdownLinkProps) => {
  const { children, className, closeDropdown, ...otherProps } = props;

  const dropdownClassName = `${className ?? ''} dropdown-item text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-foreground hover:bg-background-light`;

  return <Link {...otherProps} className={dropdownClassName} onClick={closeDropdown}>{children}</Link>;
};

export default DropdownLink;
