
const demoUsers = import.meta.env.VITE_DEMO_USERS.split(',');

const getRandomInt = (max: number): number => {
  return Math.floor(Math.random() * max);
};

export const getDemoUserEmail = (): string => {
  return demoUsers[getRandomInt(demoUsers.length)];
};
