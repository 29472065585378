import React from 'react';

interface InitialAvatarProps extends React.HTMLAttributes<HTMLElement> {
  name: string;
}

const InitialAvatar: React.FC<InitialAvatarProps> = (props: InitialAvatarProps) => {
  const { name, className, ...divProps } = props;
  const initials = name.toUpperCase().split(' ').map(s => s.charAt(0)).join('');

  return <div className={`bg-nav text-nav-text align-middle text-lg p-1 py-1 rounded-full w-9 h-9 my-1 mr-1 outline outline-white outline-1 hover:outline-light-dark text-center flex ${className ?? ''}`} {...divProps}>
    <div className='m-auto'>{initials}</div>
  </div>;
};

export default InitialAvatar;
