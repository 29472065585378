import React from 'react';
import { Cell, Legend, Pie, PieChart, Tooltip } from 'recharts';
import { CategoricalChartState } from 'recharts/types/chart/generateCategoricalChart';

import { useBrandContext } from '../../contexts/BrandContext';
import { generateGradientOfColorsHex } from '../../libs/libColor';
import { sortBy } from '../../libs/utils';
import { IThemeColorsKey } from '../../types/IThemeColors';
import { WDPieChartWidgetConfiguration } from '../../types/WDDashboardWidget';
import ChartWidget from './ChartWidget';
import { DashboardWidgetProps } from './DashboardWidgetProps';

const PieChartWidget: React.FC<DashboardWidgetProps> = (props: DashboardWidgetProps) => {
  const brandContext = useBrandContext();

  const config: WDPieChartWidgetConfiguration = props.def.configuration as WDPieChartWidgetConfiguration;

  const clickHandler = (nextState: CategoricalChartState, event: any): void => {
    console.log(nextState, event);
  };

  const colors = generateGradientOfColorsHex(brandContext.theme.colors.nav);
  const chartColors = [0, 4, 7, 1, 5, 8, 2, 6, 9, 3].map((v) => colors[v]);

  const getFillColor = (idx: number, element: { color?: string }): string => {
    if (element.color != null) {
      if (element.color.includes('#')) {
        return element.color;
      }
      const themeColor = element.color as IThemeColorsKey;
      return `#${brandContext.theme.colors[themeColor]}`;
    }
    return `#${chartColors[idx % chartColors.length]}`;
  };

  const getLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    value
  }: {
    cx: number;
    cy: number;
    midAngle: number;
    innerRadius: number;
    outerRadius: number;
    value: number; }): React.ReactNode => {
    const RADIAN = Math.PI / 180;
    // eslint-disable-next-line
    const radius = 25 + innerRadius + (outerRadius - innerRadius);
    // eslint-disable-next-line
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    // eslint-disable-next-line
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    return <text x={x} y={y} fill={`#${brandContext.theme.lightPageColors.foreground}`} textAnchor={x > cx ? 'start' : 'end'} dominantBaseline='central'>{value}</text>;
  };

  const renderLegendText = (value: string, entry: any): React.ReactNode => {
    return <span style={{ color: `#${brandContext.theme.lightPageColors.foreground}`, fontSize: '14px' }}>{value}</span>;
  };

  if (config.pies.reduce((t, v) => t + v.data.length, 0) === 0) {
    return <>
      <ChartWidget {...props}>
        <div className='w-full h-full flex'>
          <div className='m-auto text-xl'>No Data To Display</div>
        </div>
      </ChartWidget>
    </>;
  }

  return (
    <ChartWidget {...props}>
      <PieChart width={200} height={200} onClick={clickHandler}>
        {(config.showTooltip ?? true) && <Tooltip />}
        {(config.showLegend ?? false) && <Legend align='right' verticalAlign='middle' layout='vertical' formatter={renderLegendText} />}
        {config.pies.map((pie, idx) => (
          <Pie key={`pie-${idx}`} data={pie.data.sort(sortBy('value', true))} dataKey='value' nameKey='label' cx='50%' cy='50%' label={(pie.showSliceLabels ?? true) ? getLabel : undefined} isAnimationActive={false}>
            {
              pie.data.map((element, index) => (
                <Cell key={`cell-${index}`} fill={getFillColor(index, element)} />
              ))
            }
          </Pie>
        ))}
      </PieChart>
    </ChartWidget>
  );
};

export default PieChartWidget;
