import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import { LeftPane, LeftPaneHeader } from '../../components/DualPaneLayout';
import RequirePermission from '../../components/RequirePermission';
import { Permission, PermissionVerb } from '../../libs/libPermissions';

interface NavItemProps {
  page: string;
  label: string;
}

const NavItem: React.FC<NavItemProps> = (props) => {
  const location = useLocation();

  const isLocationSelected = location.pathname === `/settings/${props.page}`;
  return <>
    <Link to={`/settings/${props.page}`}>
      <div className={`w-full rounded-md p-2 hover:bg-hover ${isLocationSelected ? 'bg-selected' : ''}`}>
        {props.label}
      </div>
    </Link>
  </>;
};

const SettingsNav: React.FC = () => {
  return (
    <LeftPane>
      <LeftPaneHeader title='Settings' />
      <div>
        <RequirePermission permission={new Permission(PermissionVerb.Read, 'brand')}>
          <NavItem page='brand' label='Brand' />
          <NavItem page='brand_apps' label='Brand Apps' />
        </RequirePermission>
      </div>
    </LeftPane>
  );
};

export default SettingsNav;
