import React from 'react';
import DropdownTreeSelect from 'react-dropdown-tree-select';

import { useAccountContext } from '../../contexts/AccountContext';
import { useUserContext } from '../../contexts/UserContext';
import DropdownTreeNode from '../../types/DropdownTreeNode';

const Account: React.FC = () => {
  const userContext = useUserContext();
  const accountContext = useAccountContext();

  const onAccountChange = (currentNode: DropdownTreeNode, selectedNodes: DropdownTreeNode[]): void => {
    if (currentNode.value == null) {
      accountContext.setAccountId(accountContext.accountId);
      return;
    }
    accountContext.setAccountId(currentNode.value);
    if (currentNode.checked === false) {
      accountContext.setAccountId(userContext.apparentUser.accountId);
    }
  };

  return (
    <>
      <div className={'py-3 pointer-events-none hidden md:block'}>
        <span className={'sm:ml-2 text-sm inline-block'}>
          Account:&nbsp;
        </span>
        {!accountContext.hasChildren && <span className={'sm:mr-2 my-auto'}>
          {accountContext.currentAccount?.name ?? userContext.apparentUser.accountName}
        </span>}
      </div>
      {accountContext.hasChildren && <>
          <DropdownTreeSelect data={accountContext.accountTree ?? []} mode="radioSelect" onChange={onAccountChange} texts={{ placeholder: ' ' }} inlineSearchInput className='account-dropdown text-dark bi' />
        </>}
    </>
  );
};

export default Account;
