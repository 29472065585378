import React from 'react';
import { Outlet } from 'react-router-dom';

import { DualPaneLayout, RightPane } from '../../components/DualPaneLayout';
import { useUserContext } from '../../contexts/UserContext';
import NoAccess from '../Error/NoAccess';
import SettingsNav from './SettingsNav';

const Settings: React.FC = () => {
  const userContext = useUserContext();
  if (!userContext.apparentUser.accountIsServiceProvider) {
    return (
      <NoAccess />
    );
  }

  return (
    <DualPaneLayout>
      <SettingsNav />
      <RightPane>
        <Outlet />
      </RightPane>
    </DualPaneLayout>
  );
};

export default Settings;
