import React, { useEffect } from 'react';

import { useBrandContext } from '../../contexts/BrandContext';
import { useUserContext } from '../../contexts/UserContext';

const BrandSelector: React.FC = () => {
  const brandContext = useBrandContext();
  const userContext = useUserContext();

  useEffect(() => {
    if (userContext.apparentUser.accountIsDemo || !userContext.isImpersonating) {
      brandContext.setBrandRef(window.location.hostname);
      return;
    }
    if (userContext.apparentUser.portalUrl != null && userContext.isImpersonating) {
      brandContext.setBrandRef(userContext.apparentUser.portalUrl);
    }
  }, [userContext.apparentUser]);

  return <></>;
};

export default BrandSelector;
