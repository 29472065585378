import React from 'react';

interface DualPaneLayoutProps {
  children: React.ReactNode;
}

export const DualPaneLayout: React.FC<DualPaneLayoutProps> = (props) => {
  return (
    <div className='w-screen md:w-[calc(100vw-64px)]'>
      <div className='lg:flex -h-screen-nav'>
        {props.children}
      </div>
    </div>
  );
};

interface LeftPaneProps {
  children: React.ReactNode;
}

export const LeftPane: React.FC<LeftPaneProps> = (props) => {
  return (
    <div className='lg:w-64 bg-background2 pt-8 pb-4 md:pt-12 px-8 md:max-h-[calc(100vh-3rem)] md:overflow-y-auto'>
      {props.children}
    </div>
  );
};

interface LeftPaneHeaderProps {
  title: React.ReactNode;
}

export const LeftPaneHeader: React.FC<LeftPaneHeaderProps> = (props) => {
  return <h2 className='hidden lg:block text-2xl'>{props.title}</h2>;
};

interface RightPaneProps {
  children: React.ReactNode;
}

export const RightPane: React.FC<RightPaneProps> = (props) => {
  return (
    <div className='lg:flex-1 lg:w-[calc(100vw-320px)] py-4 md:py-12 max-h-[calc(100vh-3rem)] overflow-x-auto md:overflow-x-hidden md:overflow-y-auto shadow shadow-background-darker'>
      {props.children}
    </div>
  );
};

interface RightPaneHeaderProps {
  title: React.ReactNode;
}

export const RightPaneHeader: React.FC<RightPaneHeaderProps> = (props) => {
  return <h2 className='text-2xl mx-8 md:mx-12'>{props.title}</h2>;
};
