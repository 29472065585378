import React from 'react';

export const getIconForSortDirection = (sortDirection: string | boolean): React.ReactNode => {
  switch (sortDirection) {
    case false:
      return <i className='bi bi-arrow-down-up opacity-20' />;
    case 'asc':
      return <i className='bi bi-arrow-down' />;
    case 'desc':
      return <i className='bi bi-arrow-up' />;
  }
};
