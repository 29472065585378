import { z } from 'zod';

import { WDCaseSchema } from './WDCase';
import { WDCaseLogEntrySchema } from './WDCaseLogEntry';

export const WDCaseDetailsSchema = z.intersection(WDCaseSchema, z.object({
  caseLog: z.array(WDCaseLogEntrySchema)
}));

type WDCaseDetails = z.infer<typeof WDCaseDetailsSchema>;

export default WDCaseDetails;
