import React from 'react';
import { z } from 'zod';

import { useQuery } from '@tanstack/react-query';

import AccountLoadingSpinner from '../../components/AccountLoadingSpinner';
import CenterLoadingSpinner from '../../components/LoadingSpinner/CenterLoadingSpinner';
import { useAccountContext } from '../../contexts/AccountContext';
import { useAPI } from '../../libs/libAPI';
import useTitle from '../../libs/useTitle';
import { sortBy } from '../../libs/utils';
import { WDNodeSchema } from '../../types/WDNode';
import DashboardChart from './DashboardChart';

const Dashboard: React.FC = () => {
  const api = useAPI();
  const accountContext = useAccountContext();

  const nodeQuery = useQuery(['nodes', accountContext.accountId], async () => {
    const response = await api.fetch(`${import.meta.env.VITE_API_URI}/v1/nodes`, {
      method: 'GET'
    });

    const nodes = await z.array(WDNodeSchema).parseAsync(await response.json());

    return nodes;
  });

  useTitle('Dashboard');

  return <div className='flex flex-col h-full p-8 w-full md:w-[calc(100vw-64px)]'>
    {nodeQuery.isLoading && <CenterLoadingSpinner />}
    {nodeQuery.data?.sort(sortBy('label')).map((node) => <DashboardChart key={node.id} nodeId={node.id} nodeLabel={node.label} />)}
    <AccountLoadingSpinner />
  </div>;
};

export default Dashboard;
