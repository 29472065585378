import { format } from 'date-fns';
import React, { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';

import {
  createColumnHelper, FilterFn, flexRender, getCoreRowModel, getFilteredRowModel,
  getPaginationRowModel, getSortedRowModel, Row, useReactTable, VisibilityState
} from '@tanstack/react-table';

import DebouncedInput from '../../components/Library/DebouncedInput';
import { getIconForSortDirection } from '../../components/Library/getIconForSortDirection';
import Pagination from '../../components/Library/Pagination';
import Select from '../../components/Library/Select';
import ColumnVisibility from '../../components/Modals/ColumnVisibility';
import { exportToCsv } from '../../libs/libCSV';
import { formatToLocalTime } from '../../libs/libDate';
import { usePaginationState } from '../../libs/usePaginationState';
import { useQueryObjectState, useQueryState } from '../../libs/useQueryState';
import { useVisibleColumns } from '../../libs/useVisibleColumns';
import { LGDataPrintInitialColumn, LGDataPrintSortField } from '../../types/LGDataPrint';
import { getDisplayValue, ISO1806_TIMESTAMP_REGEX } from './getDisplayValue';

interface TableRenderProps {
  tableKey: string;
  label?: string;
  systemName: string;
  columns?: string[];
  sort?: LGDataPrintSortField[];
  initialColumns?: LGDataPrintInitialColumn[];
  data: any[][] | null;
};

export const SimpleTable: React.FC<TableRenderProps> = (props: TableRenderProps) => {
  if (props.data == null) {
    return <><div className='mx-12'>No Data To Display</div></>;
  }
  return <>
    <div className='flex mx-12 mb-2'>
      <h2 className='text-2xl'>
        {props.label ?? 'Select something to view'}
      </h2>
    </div>
    <table className='mx-12'>
      <tbody>
        {props.data.map((row, idx) => (
          <tr key={idx} className='hover:bg-hover border-y border-y-background3-dark'>
            {row.map((col, idx) => (
              <td key={idx} className={`p-2 whitespace-nowrap ${idx === 0 ? 'text-right font-bold' : 'text-left'}`}>{getDisplayValue(col)}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  </>;
};

const filter: FilterFn<any> = (row: Row<any>, columnId: string, filterValue: any): boolean => {
  const value = row.getValue(columnId);
  const filter = filterValue.toLowerCase();
  if (typeof value === 'number') {
    return value.toString().includes(filter);
  }
  if (typeof value === 'boolean') {
    return (value ? 'true' : 'false').includes(filter);
  }
  if (typeof value === 'string') {
    if (ISO1806_TIMESTAMP_REGEX.test(value)) {
      return formatToLocalTime(value).toLowerCase().includes(filter);
    }
    return value.toLowerCase().includes(filter);
  }
  return false;
};

const columnHelper = createColumnHelper<any>();

interface CellProps {
  rowIndex: number;
  value: any;
}

const getDefaultColumns = (columnNames?: string[], initialColumns?: LGDataPrintInitialColumn[]): VisibilityState => {
  if (initialColumns == null) {
    return columnNames?.reduce((a, v, idx) => ({ ...a, [v]: idx < 6 }), {}) ?? {};
  }

  const state: VisibilityState = {};
  let shownCount = 0;

  initialColumns.forEach((v) => {
    state[v.name] = v.show && shownCount < 7;
    if (v.show) {
      shownCount++;
    }
  });

  return state;
};

const cleanSortColumns = (label?: string, validColumns?: string[], sort?: LGDataPrintSortField[]): LGDataPrintSortField[] => {
  const validSort: LGDataPrintSortField[] = [];

  sort?.forEach((v) => {
    if ((validColumns?.findIndex((c) => c === v.id) ?? -1) !== -1) {
      validSort.push(v);
    } else {
      console.warn('Table missing column from sort data', label, v.id);
    }
  });

  return validSort;
};

const Cell: React.FC<CellProps> = (props: CellProps) => {
  const { search } = useLocation();
  return <Link to={`${props.rowIndex.toString()}?parentQuery=${encodeURIComponent(search)}`} className='underline'>{getDisplayValue(props.value)}</Link>;
};

export const Table: React.FC<TableRenderProps> = (props: TableRenderProps) => {
  const [sorting, setSorting] = useQueryObjectState('sort', cleanSortColumns(props.label, props.columns, props.sort));
  const [globalFilter, setGlobalFilter] = useQueryState('q', '');
  const [pagination, setPagination] = usePaginationState();

  const defaultVisibleColumns: VisibilityState = getDefaultColumns(props.columns, props.initialColumns);

  const [visibleColumns, setVisibleColumns] = useVisibleColumns(props.tableKey, getDefaultColumns(props.columns, props.initialColumns));

  const columns = useMemo(() => {
    if (props.columns == null) return [];

    return props.columns.map((column, idx) => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return columnHelper.accessor(props.columns![idx], {
        header: () => column,
        cell: info => <Cell rowIndex={info.row.index} value={info.getValue()} />,
        enableSorting: true
      });
    });
  }, [props.columns]);

  const data = useMemo(() => {
    if (props.columns == null) return [];

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return props.data?.map((row) => row.reduce((a, v, idx) => ({ ...a, [props.columns![idx]]: v }), {}));
  }, [props.data, props.columns]);

  const table = useReactTable({
    columns,
    data: data ?? [],
    state: {
      sorting,
      globalFilter,
      pagination,
      columnVisibility: visibleColumns
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    onColumnVisibilityChange: setVisibleColumns,
    globalFilterFn: filter
  });

  if (props.data == null || props.columns == null || data == null) {
    return <><div className='mx-12'>No Data To Display</div></>;
  }

  const exportData = (): void => {
    exportToCsv(`${props.systemName}-${props.label ?? 'null'}-${format(new Date(), 'yyyy-MM-dd')}.csv`, data);
  };

  return <>
    <div className='flex mx-12'>
      <h2 className='text-2xl'>
        {props.label ?? 'Select something to view'}
      </h2>
      <div className='ml-auto flex'>
      <button className='whitespace-nowrap mr-3 px-2 rounded hover:bg-hover' onClick={exportData}><i className='bi bi-download' /> Export</button>
        <ColumnVisibility columnDisplayNames={props.columns} currentVisibility={visibleColumns} setVisibility={setVisibleColumns} defaultVisibility={defaultVisibleColumns} />
        <DebouncedInput value={globalFilter} onChange={setGlobalFilter} debounce={500} placeholder='Filter Data' className='inline-block' />
      </div>
    </div>
    <div>
      <table className='mx-12 md:w-[calc(100vw-416px)] table-fixed'>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id} className='border-b border-b-background3-dark'>
              {headerGroup.headers.map((header) => (
                <th key={header.id} className='py-3 text-left px-2'>
                  {header.isPlaceholder
                    ? null
                    : <div onClick={header.column.getToggleSortingHandler()} className='flex cursor-pointer select-none whitespace-nowrap overflow-none overflow-ellipsis'>
                        <div className='inline-block'>{flexRender(header.column.columnDef.header, header.getContext())}</div>
                        <div className='inline-block ml-auto pl-1'>{getIconForSortDirection(header.column.getIsSorted())}</div>
                      </div>}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => {
            return (
              <tr key={row.id} className='border-b border-b-background3-dark hover:bg-hover'>
                {row.getVisibleCells().map((cell) => {
                  return (
                    <td key={cell.id} className='p-2 whitespace-nowrap overflow-hidden overflow-ellipsis'>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={table.getVisibleFlatColumns().length}>
              <div className='flex flex-row w-full'>
                <div className='inline-block w-1/5'>
                  {props.data.length} Total Row(s)
                </div>
                <div className='w-3/5 flex'>
                  <div className='inline-block mx-auto'>
                    <Pagination currentPage={table.getState().pagination.pageIndex} pageCount={table.getPageCount()} setPageIndex={table.setPageIndex} prevPage={table.previousPage} nextPage={table.nextPage} />
                  </div>
                </div>
                <div className='w-1/5 flex'>
                  <div className='inline-block ml-auto'>
                    <span>Show: </span>
                    <Select value={table.getState().pagination.pageSize} onChange={(e) => { table.setPageSize(Number(e.target.value)); }}>
                      {[10, 15, 20, 50, 100, 250].map((pageSize) => (
                        <option key={pageSize} value={pageSize}>{pageSize}</option>
                      ))}
                    </Select>
                  </div>
                </div>
              </div>

            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  </>;
};
