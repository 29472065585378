import React, { useState } from 'react';
import toast from 'react-hot-toast/headless';
import { useLocation } from 'react-router-dom';

import { useAccountContext } from '../../../contexts/AccountContext';
import { useAPI } from '../../../libs/libAPI';
import { createCase } from '../../../libs/libCase';
import {
  CaseCategory, CaseOrigin, CasePriority, CaseSeverity, CaseState, CaseStatus, CaseType
} from '../../../types/CaseEnums';
import Button from '../../Library/Button';
import Input from '../../Library/Input';
import { Modal, ModalBody, ModalFooter, ModalHeader } from '../../Library/Modal';
import TextArea from '../../Library/TextArea';
import PortalComponent from '../../PortalComponent';

interface FeedbackModalProps {
  show: boolean;
  close: () => void;
}

const FeedbackModal: React.FC<FeedbackModalProps> = (props: FeedbackModalProps) => {
  const api = useAPI();
  const location = useLocation();
  const accountContext = useAccountContext();

  const [submitting, setSubmitting] = useState(false);
  const [subject, setSubject] = useState('');
  const [body, setBody] = useState('');

  const submitHandler = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    setSubmitting(true);
    const stuff = async (): Promise<void> => {
      try {
        await createCase(api, {
          caseType: CaseType.Feedback,
          category: CaseCategory.Feedback,
          origin: CaseOrigin.Portal,
          priority: CasePriority.Low,
          severity: CaseSeverity.Minor,
          state: CaseState.Active,
          status: CaseStatus.New,
          title: subject.trim(),
          description: `${body.trim()}\n\n--------------\nPortal Context\n--------------\nPortal Url: ${window.location.hostname}\nCurrent Location: ${location.pathname}\nSelected Account: ${accountContext.currentAccount?.name ?? 'N/A'}`
        });

        if (accountContext.currentAccount?.serviceProviderId == null) {
          toast.success('`echo $USER_FEEDBACK > /dev/null`. Thank You For Your Feedback.');
        } else {
          toast.success('Thank You for your feedback.');
        }

        setSubmitting(false);
        setSubject('');
        setBody('');
        props.close();
      } catch {
        setSubmitting(false);
        toast.error('We failed to submit your feedback.');
      }
    };
    void stuff();
  };

  const bodyChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
    setBody(event.target.value);
  };

  return (
    <PortalComponent container={'#feedback-modal'}>
      <form onSubmit={submitHandler}>
        <Modal show={props.show} modalSize='Normal' closeModal={props.close}>
          <ModalHeader closeModal={props.close}>Feedback</ModalHeader>
          <ModalBody>
            <Input type='text' placeholder='Subject' value={subject} onChange={(e) => setSubject(e.target.value)} className='mb-2' />
            <TextArea onChange={bodyChange} value={body} rows={5} placeholder='Put your feedback here...' />
          </ModalBody>
          <ModalFooter>
            <Button variant='primary' type='submit' disabled={subject.trim().length === 0 || body.trim().length === 0 || submitting}>Submit Feedback</Button>
            <Button variant='secondary' type='button' onClick={props.close}>Close</Button>
          </ModalFooter>
        </Modal>
      </form>
    </PortalComponent>
  );
};

export default FeedbackModal;
