import React, { useMemo } from 'react';

import {
  createColumnHelper, flexRender, getCoreRowModel, getSortedRowModel, useReactTable
} from '@tanstack/react-table';

import WDTableData, { WDTableDataColumn } from '../../types/WDTableData';
import { getIconForSortDirection } from '../Library/getIconForSortDirection';
import FormattedCell from './FormatedCell';

interface DynamicTableProps {
  tableData: WDTableData;
}

const getColumnWidths = (columns: WDTableDataColumn[]): { [key: string]: string } => {
  const t = (w: number): number => (0.5 * w) + 0.5;

  const totalWidth = columns.reduce((prev, value) => {
    const width = value.width ?? 1;
    return prev + t(width);
  }, 0);

  return Object.fromEntries(columns.map((column) => {
    return [column.name, `${((t(column.width ?? 1) / totalWidth) * 100)}%`];
  }));
};

const getTitleString = (value: unknown): string | undefined => {
  if (typeof value === 'string') {
    return value;
  }
  if (typeof value === 'number') {
    return value.toString();
  }
};

const columnHelper = createColumnHelper<any>();

const DynamicTable: React.FC<DynamicTableProps> = (props: DynamicTableProps) => {
  const columns = useMemo(() => {
    const columns = props.tableData.columns.map((column) =>
      columnHelper.accessor(column.name, {
        header: () => column.displayName,
        cell: info => <FormattedCell cellContext={info} column={column} />,
        enableSorting: column.enableSort,
        invertSorting: column.sortInverted,
        enableColumnFilter: column.enableFilter
      })
    );
    return columns;
  }, [props.tableData]);

  const columnWidths = getColumnWidths(props.tableData.columns);

  const table = useReactTable({
    columns,
    data: props.tableData.data,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel()
  });

  return <>
    <table className='w-full table-fixed'>
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id} className='border-b border-b-background3-dark'>
            {headerGroup.headers.map((header) => (
              <th key={header.id} className='py-3 text-left px-2' style={{ width: columnWidths[header.column.id] }}>
                {header.isPlaceholder
                  ? null
                  : <div onClick={header.column.getToggleSortingHandler()} className={`flex ${header.column.getCanSort() ? 'cursor-pointer select-none' : ''}`}>
                    <div className='inline-block'>{flexRender(header.column.columnDef.header, header.getContext())}</div>
                    <div className='inline-block ml-auto'>{getIconForSortDirection(header.column.getIsSorted())}</div>
                  </div>
                }
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map((row) => (
          <tr key={row.id} className='border-b border-b-background3-dark hover:bg-hover'>
            {row.getVisibleCells().map((cell) => {
              return (
                <td key={cell.id} className='p-2 whitespace-nowrap overflow-hidden overflow-ellipsis' title={getTitleString(cell.getValue())} style={{ width: columnWidths[cell.column.id] }}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              );
            })}
          </tr>
        ))}
      </tbody>
    </table>
  </>;
};

export default DynamicTable;
