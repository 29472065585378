const CRM_CASE_URL_REGEX = /https?:\/\/dataendure\.crm\.dynamics\.com.*&amp;id=(?<caseId>[a-f0-9-]+)[^"]*/gmi;

export const cleanCaseActivityHtml = (html: string): string => {
  // Setup base target so links link in parent open in new tab.
  html = `<base target="_blank" />${html}`;

  // Rewrite crm case urls to link within portal
  // Ex. https://dataendure.crm.dynamics.com/main.aspx?etc=112&id=ef7519b2-99a1-ed11-aad1-00224805fa72&histKey=846259290&newWindow=true&pagetype=entityrecord&appid=237944bf-3da7-e911-a97f-000d3a363c81
  html = html.replaceAll(CRM_CASE_URL_REGEX, '/cases/$<caseId>" target="_parent');

  return html;
};
