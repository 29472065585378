import React from 'react';

import { WDWidgetType } from '../../types/WDDashboardWidget';
import BarChartWidget from './BarChartWidget';
import { DashboardWidgetProps } from './DashboardWidgetProps';
import FakeWidget from './FakeWidget';
import PieChartWidget from './PieChartWidget';
import ScoreBoardWidget from './ScoreBoardWidget';
import SimpleTableWidget from './SimpleTableWidget';
import TableWidget from './TableWidget';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
FakeWidget; // ! Exists to keep from breaking import use.

type WidgetMap = {
  [key in WDWidgetType]: React.FC<DashboardWidgetProps>;
};

const WIDGET_MAP: WidgetMap = {
  bar: BarChartWidget,
  pie: PieChartWidget,
  scoreboard: ScoreBoardWidget,
  simple_table: SimpleTableWidget,
  table: TableWidget
};

const DashboardWidget: React.FC<DashboardWidgetProps> = (props: DashboardWidgetProps) => {
  const Widget = WIDGET_MAP[props.def.type];
  return <>
    <div className={'border border-background-darker m-2 shadow shadow-background-darker w-full md:w-1/3 rounded-lg'}>
      <div className={'bg-secondary text-secondary-text border-b-secondary-darker w-full h-8 pl-2 pt-1 rounded-t-lg'}>
        {props.def.label}
      </div>
      <div className='p-2 bg-light text-dark rounded-b-lg'>
        <Widget {...props} />
      </div>
    </div>
  </>;
};

export default DashboardWidget;
