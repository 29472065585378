import { useOutletContext } from 'react-router-dom';

import { LGDataPrintSortField } from '../../types/LGDataPrint';

export interface NodeOutletContext {
  columns?: string[];
  data: any[][] | null;
  sort?: LGDataPrintSortField[];
}

export const useNodeOutletContext = (): NodeOutletContext => {
  return useOutletContext<NodeOutletContext>();
};
