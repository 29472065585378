export enum CaseCategory {
  SOC = 'soc',
  NOC = 'noc',
  Helpdesk = 'helpdesk',
  Support = 'support',
  Feedback = 'feedback'
}

export enum CaseClass {
  Case = 'case',
  Incident = 'incident'
}

export enum CaseOrigin {
  Phone = 'phone',
  Email = 'email',
  Web = 'web',
  Alert = 'alert',
  Portal = 'portal',
  API = 'api',
  Iot = 'iot'
}

export enum CasePriority {
  Low = 'low',
  Normal = 'normal',
  High = 'high',
  Critical = 'critical'
}

export enum CaseSeverity {
  Minor = 'minor',
  Moderate = 'moderate',
  Major = 'major',
  Critical = 'critical'
}

export enum CaseStatus {
  WorkInProgress = 'wip',
  Hold = 'hold',
  WaitingOnCustomer = 'woc',
  WaitingOnPartner = 'wop',
  Solved = 'solved',
  Cancelled = 'cancelled',
  Answered = 'answered',
  Duplicate = 'duplicate',
  Quarantine = 'quarantine',
  New = 'new',
  Completed = 'completed',
  Reopen = 'reopen'
}

export enum CaseState {
  Active = 'active',
  Resolved = 'resolved',
  Cancelled = 'cancelled'
}

export enum CaseType {
  Question = 'question',
  Problem = 'problem',
  Request = 'request',
  Feedback = 'feedback',
  Feature = 'feature',
  Incident = 'incident'
}

export enum IncidentType {
  L0 = 'L0',
  L1 = 'L1',
  L2 = 'L2',
  L3 = 'L3',
  L4 = 'L4',
  L5 = 'L5',
  L6 = 'L6',
  L7 = 'L7',
  L8 = 'L8',
  L9 = 'L9'
}
