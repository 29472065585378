import React from 'react';

interface ModalProps {
  show: boolean;
  children: React.ReactNode;
  closeModal: () => void;
  modalSize?: ModalSize;
}

export type ModalSize = 'Normal' | 'Large' | 'Screen';

export const Modal: React.FC<ModalProps> = (props) => {
  return <>
    <div className={`modal fade bg-black/70 fixed top-0 left-0 w-full h-full outline-none overflow-x-hidden overflow-y-auto ${props.show ? 'block show' : 'hidden'}`} onClick={props.closeModal}>
      <div className={`modal-dialog relative pointer-events-none mx-auto mt-0 lg:mt-20 z-50 ${(props.modalSize ?? 'Normal') === 'Normal' ? 'w-144' : ''}  ${props.modalSize === 'Large' ? 'lg:w-4/5 lg:max-w-screen-lg' : ''}${props.modalSize === 'Screen' ? 'w-screen max-w-[100vw] lg:w-[calc(100vw-480px)] lg:max-w-[calc(100vw-480px)] max-h-screen lg:max-h-[calc(100vh-120px)]' : ''}`} onClick={(e) => { e.stopPropagation(); }}>
        <div
          className={`modal-content shadow-lg relative flex flex-col w-full pointer-events-auto bg-background bg-clip-padding outline-none text-foreground ${props.modalSize === 'Screen' ? 'max-h-screen h-screen lg:max-h-[calc(100vh-120px)] lg:h-[calc(100vh-120px)]' : ''}`}>
          {props.children}
        </div>
      </div>
    </div>
  </>;
};

interface ModalComponentProps extends React.HTMLAttributes<HTMLElement> {};

interface ModalHeaderProps extends ModalComponentProps {
  closeModal: () => void;
  optionButtons?: React.ReactNode;
}

export const ModalHeader: React.FC<ModalHeaderProps> = (props) => {
  const { className, children, closeModal, optionButtons, ...otherProps } = props;

  return <div className={`modal-header flex flex-shrink-0 items-center justify-between px-4 py-3 bg-nav text-nav-text ${className ?? ''}`} {...otherProps}>
    <h5 className={'text-xl font-medium leading-normal text-nav-text'} {...otherProps}>{children}</h5>
    {optionButtons}
    <button type="button" className="box-content w-4 h-4 text-nav-txt border-none rounded-none focus:shadow-none focus:outline-none hover:text-nav-text-dark hover:opacity-75 hover:no-underline" aria-label="Close" onClick={closeModal}><i className="bi bi-x-lg"></i></button>
  </div>;
};

export const ModalBody: React.FC<ModalComponentProps> = (props) => {
  const { className, children, ...otherProps } = props;

  return <div className={`modal-body relative p-4 max-h-full ${className ?? ''}`} {...otherProps}>{children}</div>;
};

export const ModalFooter: React.FC<ModalComponentProps> = (props) => {
  const { className, children, ...otherProps } = props;

  return <div className={`modal-footer flex flex-shrink-0 flex-wrap items-center justify-end px-4 py-2 ${className ?? ''}`} {...otherProps}>
          {children}
      </div>;
};
