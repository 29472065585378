import React from 'react';

import { CellContext } from '@tanstack/react-table';

import { formatToLocalTime, humanizedTimeSpan, toLocalTime } from '../../libs/libDate';
import { WDTableDataColumn, WDTableDataColumnType } from '../../types/WDTableData';

interface FormattedCellProps {
  cellContext: CellContext<any, any>;
  column: WDTableDataColumn;
};

const FormattedCell: React.FC<FormattedCellProps> = (props: FormattedCellProps) => {
  const { cellContext, column } = props;

  switch (column.type) {
    case WDTableDataColumnType.HumanDateTime:
      return <span title={formatToLocalTime(cellContext.getValue())}>{humanizedTimeSpan(toLocalTime(cellContext.getValue()))}</span>;
    case WDTableDataColumnType.DateTime:
      return formatToLocalTime(cellContext.getValue());
    case WDTableDataColumnType.Boolean:
      if (cellContext.getValue() === true) {
        return <span className='text-success'><i className="bi bi-check-circle-fill"></i></span>;
      }
      if (cellContext.getValue() === false) {
        return <span className='text-danger'><i className="bi bi-x-circle-fill"></i></span>;
      }
      return <span><i className="bi bi-question-circle-fill"></i></span>;
    default:
      return cellContext.getValue();
  }
};

export default FormattedCell;
