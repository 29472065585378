import React from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { captureException } from '@sentry/react';
import { useQuery } from '@tanstack/react-query';

import Frame from '../../components/Library/Frame';
import { Modal, ModalBody, ModalHeader } from '../../components/Library/Modal';
import LoadingSpinner from '../../components/LoadingSpinner';
import { useAPI } from '../../libs/libAPI';
import { formatToLocalTime } from '../../libs/libDate';
import { enumKeyByValue } from '../../libs/utils';
import { CaseOrigin, CaseSeverity, CaseStatus, CaseType } from '../../types/CaseEnums';
import WDCaseDetails, { WDCaseDetailsSchema } from '../../types/WDCaseDetails';
import CaseActivity from './CaseActivity';

interface Detail {
  label: string;
  getValue: (caseDetail: WDCaseDetails) => React.ReactNode;
}

const DETAILS: Detail[] = [
  {
    label: 'Case Number',
    getValue: (c) => c.ticketNumber
  },
  {
    label: 'Created On',
    getValue: (c) => formatToLocalTime(c.createdAt)
  },
  {
    label: 'Case Status',
    getValue: (c) => enumKeyByValue(CaseStatus, c.status)
  },
  {
    label: 'Case Type',
    getValue: (c) => enumKeyByValue(CaseType, c.caseType)
  },
  {
    label: 'Requester',
    getValue: (c) => c.createdByName
  },
  {
    label: 'Case Origin',
    getValue: (c) => c.origin != null ? enumKeyByValue(CaseOrigin, c.origin) : <i>null</i>
  },
  {
    label: 'Case Severity',
    getValue: (c) => enumKeyByValue(CaseSeverity, c.severity)
  }
];

const CaseDetails: React.FC = () => {
  const navigate = useNavigate();
  const [queryParams] = useSearchParams();

  const closeCase = (): void => {
    navigate(`..${decodeURIComponent(queryParams.get('casesQuery') ?? '')}`);
  };

  return <>
    <Modal show={true} closeModal={closeCase} modalSize='Screen'>
      <InnerCaseDetails />
    </Modal>
  </>;
};

const InnerCaseDetails: React.FC = () => {
  const { caseId } = useParams();
  const [queryParams] = useSearchParams();
  const api = useAPI();
  const navigate = useNavigate();

  const caseDetailsQuery = useQuery(['case', caseId], async () => {
    // if (caseId == null) throw Error('Missing Case Id');
    const response = await api.fetch(`${import.meta.env.VITE_API_URI}/v1/cases/${caseId ?? 'null'}`, { method: 'GET' });
    const details = await WDCaseDetailsSchema.parseAsync(await response.json());

    return details;
  });

  const closeCase = (): void => {
    navigate(`..${decodeURIComponent(queryParams.get('casesQuery') ?? '')}`);
  };

  if (caseDetailsQuery.isLoading) {
    return <>
      <ModalHeader closeModal={closeCase}>Loading...</ModalHeader>
      <ModalBody>
        <div className='flex w-full pt-2'>
          <div className='mx-auto mt-20 text-center'>
            <LoadingSpinner className='h-32 w-32' />
            <p>Loading Case Details</p>
          </div>
        </div>
      </ModalBody>
    </>;
  }

  if (caseDetailsQuery.isError) {
    captureException(caseDetailsQuery.error);
    return <></>;
  }

  const caseDetails = caseDetailsQuery.data;

  return <>
    <ModalHeader closeModal={closeCase}>{caseDetails.title != null ? caseDetails.title : <i>Missing Subject</i>}</ModalHeader>
    <ModalBody className='overflow-y-auto'>
      <div className='lg:flex w-full'>
        <div className='lg:w-96 pb-2'>
          <Frame varient='nav' title={<span className='text-xl font-bold'>Details</span>}>
            <table className='w-full'>
              <tbody>
                {DETAILS.map((detail) => (
                  <tr key={detail.label}>
                    <td className='font-bold py-1 pr-1'>{detail.label}</td>
                    <td>{detail.getValue(caseDetails)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Frame>

        </div>
        <div className='lg:flex-1 max-w-[100vw] lg:max-w-[calc(100vw-544px)] max-h-full overflow-y-auto'>
          <Frame varient='nav' title={<span className='text-xl font-bold'>Activities</span>}>
            {caseDetails.caseLog.map((logEntry) => <CaseActivity activity={logEntry} key={logEntry.id} />)}
          </Frame>
        </div>
      </div>
    </ModalBody>
  </>;
};

export default CaseDetails;
