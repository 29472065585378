import { z } from 'zod';

export const LGDataPrintSortFieldSchema = z.object({
  id: z.string(),
  desc: z.boolean()
});

export type LGDataPrintSortField = z.infer<typeof LGDataPrintSortFieldSchema>;

export const LGDataPrintInitialColumnSchema = z.object({
  name: z.string(),
  show: z.boolean()
});

export type LGDataPrintInitialColumn = z.infer<typeof LGDataPrintInitialColumnSchema>;

export const LGDataPrintViewDefinitionSchema = z.object({
  Views: z.array(z.object({
    Title: z.string(),
    ViewType: z.ostring(),
    Items: z.array(z.object({
      Type: z.string(),
      Label: z.string(),
      Path: z.ostring(),
      Rows: z.optional(z.array(z.array(z.string()))),
      Columns: z.optional(z.array(z.string())),
      DefaultSorted: z.optional(z.array(LGDataPrintSortFieldSchema)),
      InitialColumns: z.optional(z.array(LGDataPrintInitialColumnSchema))
    }))
  }))
});

export const LGDataPrintViewSchema = z.object({
  ViewDefinition: LGDataPrintViewDefinitionSchema
});

export const LGDataPrintSystemSchema = z.object({
  Inspector: z.object({
    Alias: z.string()
  })
});

export const LGDataPrintSchema = z.object({
  raw: z.any(),
  system: LGDataPrintSystemSchema,
  view: LGDataPrintViewSchema,
  views: z.record(z.string(), z.array(z.array(z.array(z.any().nullable())).nullable()))
});

type LGDataPrint = z.infer<typeof LGDataPrintSchema>;

export default LGDataPrint;
