import { z } from 'zod';

export const WDToolSchema = z.object({
  name: z.string(),
  url: z.string().nullable(),
  urlRequired: z.boolean()
});

type WDTool = z.infer<typeof WDToolSchema>;

export default WDTool;
