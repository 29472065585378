import React from 'react';
import { Bar, BarChart, CartesianGrid, Cell, Legend, Tooltip, XAxis, YAxis } from 'recharts';
import { CategoricalChartState } from 'recharts/types/chart/generateCategoricalChart';

import { useBrandContext } from '../../contexts/BrandContext';
import { generateGradientOfColorsHex } from '../../libs/libColor';
import { GenericDataElement } from '../../types/GenericDataList';
import { IThemeColorsKey } from '../../types/IThemeColors';
import { WDBarChartWidgetConfiguration } from '../../types/WDDashboardWidget';
import ChartWidget from './ChartWidget';
import { DashboardWidgetProps } from './DashboardWidgetProps';

const BarChartWidget: React.FC<DashboardWidgetProps> = (props: DashboardWidgetProps) => {
  const brandContext = useBrandContext();
  const config: WDBarChartWidgetConfiguration = props.def.configuration as WDBarChartWidgetConfiguration;

  const clickHandler = (nextState: CategoricalChartState, event: any): void => {
    console.log(nextState, event);
  };

  const colors = generateGradientOfColorsHex(brandContext.theme.colors.nav);
  const chartColors = [0, 4, 7, 1, 5, 8, 2, 6, 9, 3].map((v) => colors[v]);

  const getFillColor = (barIdx: number, cellIdx: number, element: GenericDataElement, bar: { colorKey?: string }): string => {
    if (config.useUniformBarColor ?? true) {
      return `#${brandContext.theme.colors.nav}`;
    }

    if (bar.colorKey != null) {
      const elementColor = element[bar.colorKey] as (string | undefined);
      if (elementColor != null) {
        if (elementColor.includes('#')) {
          return elementColor;
        }
        const themeColor = elementColor as IThemeColorsKey;
        return `#${brandContext.theme.colors[themeColor]}`;
      }
    }

    return `#${chartColors[cellIdx % chartColors.length]}`;
  };

  return (
    <ChartWidget {...props}>
      <BarChart width={450} height={300} data={props.def.data} onClick={clickHandler}>
        {(config.showGrid ?? true) && <CartesianGrid strokeDasharray='3 3' />}
        {(config.showLegend ?? false) && <Legend />}
        {(config.showTooltip ?? true) && <Tooltip />}
        <XAxis dataKey={config.xAxisLabelKey} />
        <YAxis dataKey={config.yAxisLabelKey} />
        {config.bars.map((bar, idx) => (
          <Bar key={`bar-${idx}`} dataKey={bar.dataKey} name={bar.label} isAnimationActive={false}>
            {
              props.def.data?.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={getFillColor(idx, index, entry, bar)} />
              ))
            }
          </Bar>
        ))}
      </BarChart>
    </ChartWidget>
  );
};

export default BarChartWidget;
