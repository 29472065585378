import React, { useState } from 'react';
import ReactJson from 'react-json-view';

import Button from '../../../components/Library/Button';
import Input from '../../../components/Library/Input';
import Select from '../../../components/Library/Select';
import { useAPI } from '../../../libs/libAPI';

const RequestDevPage: React.FC = () => {
  const api = useAPI();
  const [method, setMethod] = useState<string>('GET');
  const [route, setRoute] = useState<string>(import.meta.env.VITE_API_URI);
  const [responseData, setResponseData] = useState<any>(null);

  const makeRequest = async (): Promise<void> => {
    const response = await api.fetch(`${route}`, {
      method
    });
    setResponseData(await response.json());
  };

  return <>
    <div className='m-5'>
      <div>
        <Select value={method} onChange={(e) => setMethod(e.target.value)}>
          {['GET', 'POST', 'PATCH'].map((method) => <option key={method} value={method}>{method}</option>)}
        </Select>
        <Input type='text' placeholder='Api Route' value={route} onChange={(e) => setRoute(e.target.value)} />
        <Button variant='primary' onClick={() => { void makeRequest(); }}>Submit</Button>
      </div>
      <div>
        <ReactJson src={responseData} theme={'google'} />
      </div>
    </div>
  </>;
};

export default RequestDevPage;
