import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Modal, ModalBody, ModalHeader } from '../../components/Library/Modal';
import CenterLoadingSpinner from '../../components/LoadingSpinner/CenterLoadingSpinner';
import { useAPI } from '../../libs/libAPI';

const AppDatasheet: React.FC = () => {
  const api = useAPI();
  const navigate = useNavigate();
  const { product } = useParams();
  const [blobUrl, setBlobUrl] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);

  const close = (): void => {
    navigate('/apps');
  };

  const fetchDatasheet = async (): Promise<void> => {
    const brandQuery = import.meta.env.VITE_DATASHEET_USE_REQUEST_BRAND === 'true' ? `?brand=${location.hostname}` : '';
    const response = await api.fetch(`${import.meta.env.VITE_API_URI}/v1/datasheet/${product ?? ''}.pdf${brandQuery}`, {
      method: 'GET'
    });

    if (response.ok) {
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      if (blobUrl != null) {
        URL.revokeObjectURL(blobUrl);
      }
      setBlobUrl(url);
    }
    setLoading(false);
  };

  useEffect(() => {
    void fetchDatasheet();
    return () => {
      if (blobUrl != null) {
        URL.revokeObjectURL(blobUrl);
      }
    };
  }, []);

  if (product == null) {
    return <></>;
  }

  const optionButton = <>
    <a className='box-content ml-auto mr-2 w-4 h-4 text-nav-txt border-none rounded-none focus:shadow-none focus:outline-none hover:text-nav-text-dark hover:opacity-75 hover:no-underline' href={blobUrl ?? ''} download={`${product}.pdf`}><i className='bi bi-download'></i></a>
  </>;

  return (
    <Modal show={true} modalSize='Large' closeModal={close}>
      <ModalHeader closeModal={close} optionButtons={optionButton}>
        {product}
      </ModalHeader>
      <ModalBody className='p-0'>
        {loading
          ? <>
              <CenterLoadingSpinner />
            </>
          : <>
              <object data={`${blobUrl ?? ''}`} type="application/pdf" className='w-full h-[70vh]'>

              </object>
            </>}
      </ModalBody>
    </Modal>
  );
};

export default AppDatasheet;
