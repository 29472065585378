import { z } from 'zod';

export enum WDTableDataColumnType {
  String = 'string',
  HumanDateTime = 'humandatetime',
  DateTime = 'datetime',
  Boolean = 'boolean',
  Number = 'number',
  Currency = 'currency', // $#,###.##
  Percentage = 'percentage', // ###.#%
  Mood = 'mood' // :) | :| | :(
}

export const WDTableDataColumnSchema = z.object({
  name: z.string(),
  displayName: z.string(),
  default: z.oboolean(),
  enableFilter: z.oboolean(),
  enableSort: z.oboolean(),
  sortInverted: z.oboolean(),
  type: z.nativeEnum(WDTableDataColumnType),
  width: z.optional(z.number().int().min(0))
});

export type WDTableDataColumn = z.infer<typeof WDTableDataColumnSchema>;

export const WDTableDataSchema = z.object({
  data: z.array(z.record(z.string(), z.any())),
  columns: z.array(WDTableDataColumnSchema),
  count: z.number().int().min(0)
});

type WDTableData = z.infer<typeof WDTableDataSchema>;

export default WDTableData;

export const testData: WDTableData = {
  count: 0,
  columns: [
    {
      name: 'computerName',
      displayName: 'Computer Name',
      type: WDTableDataColumnType.String
    }
  ],
  data: [
    {
      computerName: 'WOPR'
    }
  ]
};
