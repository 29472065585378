import React from 'react';

import { Variant } from '../../types/IThemeColors';

interface FrameProps {
  className?: string;
  title?: React.ReactNode;
  children?: React.ReactNode;
  varient: Variant;
}

const Frame: React.FC<FrameProps> = (props: FrameProps) => {
  const { className, title, children, varient } = props;

  return (
    <div className={`${className ?? ''} border border-background-darker m-2 shadow shadow-background-darker`}>
      {title != null &&
        <div className={`bg-${varient} text-${varient}-text shadow shadow-${varient}-dark w-full h-8 pl-2 pt-1`}>
          {title}
        </div>
      }
      <div className='p-2'>
        {children}
      </div>
    </div>
  );
};

export default Frame;
