import React, { ReactNode } from 'react';
import iconFile from '../assets/icons.svg';

import iconMap from '../data/iconMap.json';

export const iconFromClass = (iconClass: string, sizeClass: string = 'text-3xl', color: string = 'forground'): ReactNode => {
  return <i className={`align-middle ${iconClass} ${sizeClass} text-${color}`} />;
};

export const getIconForService = (serviceName: string, iconSizeClass: string = 'text-3xl', imageSizeClass: string = 'h-8 w-8', color: string = 'foreground'): ReactNode => {
  const map: { [key: string]: string } = iconMap;
  if (Object.keys(map).includes(serviceName)) {
    const iconName = map[serviceName];
    if (iconName.includes('.')) {
      return <img className={imageSizeClass} src={`/icons/${iconName}`} alt={serviceName} />;
    } else if (iconName.includes('#')) {
      return (
        <svg className={`fill-${color} ${imageSizeClass}`}>
          <use href={`${iconFile}${iconName.toLowerCase()}`} />
        </svg>
      );
    } else {
      return iconFromClass(iconName, iconSizeClass, color);
    }
  }

  // Return default icon
  return iconFromClass('bi-app', iconSizeClass, color);
};
