import React, { useEffect, useMemo } from 'react';
import { Link, Outlet, useNavigate, useParams } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';

import {
  DualPaneLayout, LeftPane, LeftPaneHeader, RightPane
} from '../../components/DualPaneLayout';
import { ErrorBoundry } from '../../components/ErrorBoundry';
import LoadingSpinner from '../../components/LoadingSpinner';
import { useAccountContext } from '../../contexts/AccountContext';
import { useAPI } from '../../libs/libAPI';
import useTitle from '../../libs/useTitle';
import { sortBy } from '../../libs/utils';
import LGDataPrint, {
  LGDataPrintInitialColumn, LGDataPrintSchema, LGDataPrintSortField
} from '../../types/LGDataPrint';
import Error from '../Error';
import NotFound from '../Error/NotFound';
import { SystemOutletContext } from './SystemOutletContext';
import { SimpleTable, Table } from './TableRender';

interface TableInfo {
  label: string;
  path: string;
  columns?: string[];
  viewKey: string;
  viewIndex: number;
  sort?: LGDataPrintSortField[];
  initialColumns?: LGDataPrintInitialColumn[];
  data: any[][] | null;
};

const getData = (dataPrint: LGDataPrint | undefined, viewKey: string, viewIndex: number): any[][] | null => {
  if (dataPrint == null) return null;
  return dataPrint.views[viewKey][viewIndex];
};

const System: React.FC = () => {
  const api = useAPI();
  const accountContext = useAccountContext();
  const navigate = useNavigate();
  const { systemId, tableName } = useParams();

  const data = useQuery(['systems', accountContext.accountId, systemId], async () => {
    const response = await api.fetch(`${import.meta.env.VITE_API_URI}/v1/systems/${systemId ?? 'null'}`, {
      method: 'GET'
    });
    const dataPrint: LGDataPrint = await LGDataPrintSchema.parseAsync(await response.json());
    return dataPrint;
  }, {
    staleTime: 60 * 60 * 1000,
    cacheTime: 4 * 60 * 60 * 1000
  });

  const tables = useMemo(() => {
    if (data.data == null) {
      return [];
    }
    return data.data.view.ViewDefinition.Views.flatMap((view) => {
      return view.Items.map((item, idx) => {
        const table: TableInfo = {
          label: item.Label,
          path: encodeURIComponent(`${view.Title}-${item.Label}`),
          columns: item.Columns,
          viewKey: view.Title,
          viewIndex: idx,
          sort: item.DefaultSorted,
          initialColumns: item.InitialColumns,
          data: getData(data.data, view.Title, idx)
        };

        return table;
      });
    }).sort(sortBy('label')).filter((v) => v.data != null);
  }, [data.data]);

  // useEffect(() => {
  //   if ((accountContext.currentAccount?.systems ?? []).find((s) => s.id === parseInt(systemId ?? '0')) == null) {
  //     navigate('/systems');
  //   }
  // }, [accountContext.currentAccount, systemId]);

  useEffect(() => {
    if (tableName == null && tables.length > 0 && systemId != null) {
      const firstTable = tables[0];
      navigate(`/systems/${systemId}/${firstTable.path}`);
    }
    if (tableName != null && systemId != null) {
      const selectedLink = document.querySelectorAll(`[href="/systems/${systemId}/${encodeURIComponent(tableName)}"]`);
      selectedLink.forEach((e) => e.scrollIntoView({ block: 'center', behavior: 'auto' }));
    }
  }, [tables, tableName]);

  if (systemId == null) {
    return <NotFound />;
  }

  const selectedTable = tables.find((table) => decodeURIComponent(table.path) === tableName);

  const systemTitle = data.data?.system.Inspector.Alias ?? 'Loading...';

  useTitle(selectedTable != null ? `Systems | ${systemTitle} | ${selectedTable.label}` : `Systems | ${systemTitle}`);

  const TableRenderer = selectedTable?.columns == null ? SimpleTable : Table;

  const tableData = selectedTable?.data ?? null;

  const outletContext: SystemOutletContext = {
    columns: selectedTable?.columns,
    data: tableData,
    sort: selectedTable?.sort
  };

  return <>
    <DualPaneLayout>
      <LeftPane>
        <h6 className='text-xs'><Link to='/systems' className='underline'>&lt;- Back To Systems</Link></h6>
        <LeftPaneHeader title={systemTitle} />
        <div>
          {tables.map((table) =>
            <Link key={table.path} to={`/systems/${systemId}/${table.path}`} title={`${table.viewKey}[${table.viewIndex}]`}>
              <div className={`w-full rounded-md p-2 hover:bg-hover ${decodeURIComponent(table.path) === tableName ? 'bg-selected' : ''}`}>
                {table.label}
              </div>
            </Link>
          )}
        </div>
      </LeftPane>
      <RightPane>
        {data.isLoading
          ? <>
          <div className='flex flex-col mx-auto my-auto pt-12'>
            <LoadingSpinner className='mx-auto w-32 h-32' />
            <div className='text-center h2 pt-2'>Loading...</div>
          </div>
        </>
          : <>
          <ErrorBoundry key={tableName ?? ''}>
            <ErrorBoundry.OK>
              <TableRenderer columns={selectedTable?.columns} data={tableData} sort={selectedTable?.sort} label={selectedTable?.label} tableKey={`${systemTitle}.${selectedTable?.path ?? 'null'}`} systemName={systemTitle} initialColumns={selectedTable?.initialColumns} />
            </ErrorBoundry.OK>
            <ErrorBoundry.Error component={Error} />
          </ErrorBoundry>
        </>}
      </RightPane>
    </DualPaneLayout>
    <Outlet context={outletContext} />
  </>;
};

export default System;
