import React from 'react';

import Button from './Button';

interface PaginationProps {
  setPageIndex: (index: number) => void;
  nextPage: () => void;
  prevPage: () => void;
  currentPage: number;
  pageCount: number;
};

const range = (start: number, end: number): number[] => Array.from({ length: (end - start) }, (v, k) => k + start);

const getPages = (currentPage: number, pageCount: number): number[] => {
  let start = Math.max(1, currentPage - 2);
  const end = Math.min(pageCount - 1, start + 5);

  start = Math.max(1, Math.min(start, end - 5));

  return range(start, end);
};

const Pagination: React.FC<PaginationProps> = (props: PaginationProps) => {
  const canPreviousPage = props.currentPage > 0;
  const canNextPage = props.currentPage < props.pageCount - 1;

  return <div>
    <Button variant='primary' onClick={() => props.prevPage()} disabled={!canPreviousPage} className='rounded-r-none mr-0'><i className='bi bi-chevron-left' /> Previous</Button>
    {props.pageCount > 0 && <Button variant='secondary' onClick={() => props.setPageIndex(0)} disabled={props.currentPage === 0} className='w-10 rounded-none mx-0'>1</Button>}
    {props.currentPage >= 4 && props.pageCount > 5 && <Button variant='secondary' disabled={true} className='w-10 rounded-none mx-0'>...</Button>}
    {getPages(props.currentPage, props.pageCount).map((pageIndex) => {
      return (
        <Button key={pageIndex} variant='secondary' onClick={() => props.setPageIndex(pageIndex)} className='w-10 rounded-none mx-0' disabled={props.currentPage === pageIndex}>{pageIndex + 1}</Button>
      );
    })}
    {props.currentPage <= props.pageCount - 5 && props.pageCount > 5 && <Button variant='secondary' disabled={true} className='w-10 rounded-none mx-0'>...</Button>}
    {props.pageCount > 1 && <Button variant='secondary' onClick={() => props.setPageIndex(props.pageCount - 1)} disabled={props.currentPage === props.pageCount - 1} className='w-10 rounded-none mx-0'>{props.pageCount}</Button>}
    <Button variant='primary' onClick={() => props.nextPage()} disabled={!canNextPage} className='rounded-l-none ml-0'>Next <i className='bi bi-chevron-right' /></Button>
  </div>;
};

export default Pagination;
