import React, { useEffect, useRef, useState } from 'react';

import { useUserContext } from '../../../contexts/UserContext';
import Button from '../../Library/Button';
import Input from '../../Library/Input';
import { Modal, ModalBody, ModalFooter, ModalHeader } from '../../Library/Modal';
import LoadingSpinner from '../../LoadingSpinner';
import PortalComponent from '../../PortalComponent';

interface ImpersonationModalProps {
  show: boolean;
  close: () => void;
};

const ImpersonationModal: React.FC<ImpersonationModalProps> = (props: ImpersonationModalProps) => {
  const userContext = useUserContext();
  const inputRef = useRef<any>();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');

  useEffect(() => {
    if (props.show) {
      inputRef.current?.focus();
    }
  }, [props.show]);

  const closeModal = (): void => {
    props.close();
    setEmail('');
  };

  const doImpersonate = (): void => {
    setIsLoading(true);
    userContext.impersonate(email)
      .then(() => {
        closeModal();
        setIsLoading(false);
      })
      .catch(() => {

      });
  };

  const submitHandler = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
  };

  return <PortalComponent container={'#impersonate-modal'}>
    <form onSubmit={submitHandler}>
      <Modal show={props.show} modalSize='Normal' closeModal={() => { props.close(); }}>
        <ModalHeader closeModal={() => { props.close(); }}>Impersonate User</ModalHeader>
        <ModalBody>
          {isLoading
            ? <>
            <div className='w-full flex align-middle justify-center'>
              <LoadingSpinner className='w-10 h-10 my-auto' />
            </div>
          </>
            : <>
            <p className='pb-2'>Enter users email below to impersonate them.</p>
            <Input type='email' value={email} onChange={(e) => setEmail(e.target.value)} placeholder='user2@impersonate.com' autoFocus ref={inputRef} />
          </>}
        </ModalBody>
        <ModalFooter>
          <Button variant='primary' type='submit' onClick={doImpersonate} disabled={isLoading}>Impersonate</Button>
          <Button variant='secondary' onClick={closeModal}>Close</Button>
        </ModalFooter>
      </Modal>
    </form>
  </PortalComponent>;
};

export default ImpersonationModal;
