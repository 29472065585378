import { z } from 'zod';

export const LGSystemSchema = z.object({
  id: z.number().int(),
  inspectorAlias: z.string(),
  name: z.string()
});

type LGSystem = z.infer<typeof LGSystemSchema>;

export default LGSystem;
