import React, { useEffect, useMemo } from 'react';
import { Link, Outlet, useNavigate, useParams } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';

import DashboardWidget from '../../components/DashboardWidget';
import {
  DualPaneLayout, LeftPane, LeftPaneHeader, RightPane
} from '../../components/DualPaneLayout';
import { ErrorBoundry } from '../../components/ErrorBoundry';
import LoadingSpinner from '../../components/LoadingSpinner';
import { useAccountContext } from '../../contexts/AccountContext';
import { useAPI } from '../../libs/libAPI';
import useTitle from '../../libs/useTitle';
import { sortBy } from '../../libs/utils';
import { LGDataPrintInitialColumn, LGDataPrintSortField } from '../../types/LGDataPrint';
import WDNodeDetails, { WDNodeDetailsSchema } from '../../types/WDNodeDetails';
import Error from '../Error';
import NotFound from '../Error/NotFound';
import { NodeOutletContext } from './NodeOutletContext';
import { SimpleTable, Table } from './TableRender';

interface TableInfo {
  label: string;
  path: string;
  columns?: string[];
  viewKey: string;
  viewIndex: number;
  sort?: LGDataPrintSortField[];
  initialColumns?: LGDataPrintInitialColumn[];
  data: any[][] | null;
};

const getData = (dataPrint: WDNodeDetails | undefined, viewKey: string, viewIndex: number): any[][] | null => {
  if (dataPrint == null || dataPrint.views == null || !Object.keys(dataPrint.views).includes(viewKey)) return null;
  return dataPrint.views[viewKey][viewIndex];
};

const NodeSource: React.FC = () => {
  const api = useAPI();
  const accountContext = useAccountContext();
  const navigate = useNavigate();
  const { nodeId, tableName } = useParams();

  const data = useQuery(['node', accountContext.accountId, nodeId], async () => {
    const response = await api.fetch(`${import.meta.env.VITE_API_URI}/v1/nodes/${nodeId ?? 'null'}`, {
      method: 'GET'
    });
    const dataPrint: WDNodeDetails = await WDNodeDetailsSchema.parseAsync(await response.json());
    return dataPrint;
  }, {
    staleTime: 60 * 60 * 1000,
    cacheTime: 4 * 60 * 60 * 1000
  });

  const tables = useMemo(() => {
    if (data.data == null || data.data.view == null) {
      return [];
    }
    return data.data.view.ViewDefinition.Views.flatMap((view) => {
      return view.Items.map((item, idx) => {
        const table: TableInfo = {
          label: item.Label,
          path: encodeURIComponent(`${view.Title}-${item.Label}`),
          columns: item.Columns,
          viewKey: view.Title,
          viewIndex: idx,
          sort: item.DefaultSorted,
          initialColumns: item.InitialColumns,
          data: getData(data.data, view.Title, idx)
        };

        return table;
      });
    }).sort(sortBy('label')).filter((v) => v.data != null);
  }, [data.data]);

  // useEffect(() => {
  //   if ((accountContext.currentAccount?.nodes ?? []).find((s) => s.id === parseInt(nodeId ?? '0')) == null) {
  //     navigate('/nodes');
  //   }
  // }, [accountContext.currentAccount, nodeId]);

  useEffect(() => {
    if (tableName == null && tables.length > 0 && nodeId != null) {
      const defaultTable = tables.find(t => t.path === data.data?.node.defaultTable) ?? tables[0];
      navigate(`/nodes/${nodeId}/${defaultTable.path}`);
    }
    if (tableName != null && nodeId != null) {
      const selectedLink = document.querySelectorAll(`[href="/nodes/${nodeId}/${encodeURIComponent(tableName)}"]`);
      selectedLink.forEach((e) => e.scrollIntoView({ block: 'center', behavior: 'auto' }));
    }
  }, [tables, tableName]);

  if (nodeId == null) {
    return <NotFound />;
  }

  const selectedTable = tables.find((table) => decodeURIComponent(table.path) === tableName);

  const systemTitle = data.data?.node.label ?? 'Loading...';

  useTitle(selectedTable != null ? `Systems | ${systemTitle} | ${selectedTable.label}` : `Systems | ${systemTitle}`);

  const TableRenderer = selectedTable?.columns == null ? SimpleTable : Table;

  const tableData = selectedTable?.data ?? null;

  const outletContext: NodeOutletContext = {
    columns: selectedTable?.columns,
    data: tableData,
    sort: selectedTable?.sort
  };

  return <>
    <DualPaneLayout>
      <LeftPane>
        <h6 className='text-xs'><Link to='/nodes' className='underline'>&lt;- Back To Node Services</Link></h6>
        <LeftPaneHeader title={systemTitle} />
        <div>
            {tables.map((table) =>
              <Link key={table.path} to={`/nodes/${nodeId}/${table.path}`} title={`${table.viewKey}[${table.viewIndex}]`}>
                <div className={`w-full rounded-md p-2 hover:bg-hover ${decodeURIComponent(table.path) === tableName ? 'bg-selected' : ''}`}>
                  {table.label}
                </div>
              </Link>
            )}
          </div>
      </LeftPane>
      <RightPane>
        {data.isLoading
          ? <>
          <div className='flex flex-col mx-auto my-auto pt-12'>
            <LoadingSpinner className='mx-auto w-32 h-32' />
            <div className='text-center h2 pt-2'>Loading...</div>
          </div>
        </>
          : <>
          <ErrorBoundry key={tableName ?? ''}>
            <ErrorBoundry.OK>
              <div className='pb-4 mx-8 hidden md:block'>
                {/* <button className='whitespace-nowrap mr-3 px-2 rounded hover:bg-hover' onClick={() => {}}><i className='bi bi-chevron-double-down' /> Hide Charts</button> */}
                <div className='first-line:hidden flex'>
                  {data.data?.charts.map((chart) => <DashboardWidget key={chart.label} def={chart} />)}
                </div>
              </div>
              <TableRenderer columns={selectedTable?.columns} data={tableData} sort={selectedTable?.sort} label={selectedTable?.label} tableKey={`${systemTitle}.${selectedTable?.path ?? 'null'}`} systemName={systemTitle} initialColumns={selectedTable?.initialColumns} />
            </ErrorBoundry.OK>
            <ErrorBoundry.Error component={Error} />
          </ErrorBoundry>
        </>}
      </RightPane>
    </DualPaneLayout>
    <Outlet context={outletContext} />
  </>;
};

export default NodeSource;
