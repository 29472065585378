import React, { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast/headless';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import Button from '../../components/Library/Button';
import Frame from '../../components/Library/Frame';
import Input from '../../components/Library/Input';
import Select from '../../components/Library/Select';
import LoadingSpinner from '../../components/LoadingSpinner';
import RequirePermission from '../../components/RequirePermission';
import { useBrandContext } from '../../contexts/BrandContext';
import { useUserContext } from '../../contexts/UserContext';
import { ThemeName, THEMES } from '../../data/themes';
import { useAPI } from '../../libs/libAPI';
import { Permission, PermissionVerb } from '../../libs/libPermissions';
import useTitle from '../../libs/useTitle';
import { WDBrand, WDBrandSchema, WDBrandUpdate } from '../../types/WDBrand';
import { WDGenericResponseSchema } from '../../types/WDGenericResponse';

const ALLOWED_FILE_TYPES = ['image/png', 'image/svg+xml', 'image/webp'];

interface BrandSettingsFormProps {
  currentBranding: WDBrand;
}

const BrandSettingsForm: React.FC<BrandSettingsFormProps> = (props) => {
  const api = useAPI();
  const queryClient = useQueryClient();
  const userContext = useUserContext();
  const brandContext = useBrandContext();

  const [brand, setBrand] = useState<WDBrandUpdate>(props.currentBranding);
  const [pageError, setPageError] = useState<string | null>(null);
  const [brandLogoError, setBrandLogoError] = useState<string | null>(null);
  const [navLogoError, setNavLogoError] = useState<string | null>(null);
  const [brandLogoUrl, setBrandLogoUrl] = useState('');
  const [navLogoUrl, setNavLogoUrl] = useState('');

  const brandLogoFileRef = useRef<HTMLInputElement | null>(null);
  const navLogoFileRef = useRef<HTMLInputElement | null>(null);

  const fetchImage = async (filename: string, setImage: (url: string) => void): Promise<void> => {
    const res = await api.fetch(`${import.meta.env.VITE_API_URI}/v1/brands/self/file/${filename}`, {
      method: 'GET'
    });
    const imageBlob = await res.blob();
    const imageObjectURL = URL.createObjectURL(imageBlob);
    setImage(imageObjectURL);
  };

  useEffect(() => {
    if (props.currentBranding.brandLogo != null) {
      void fetchImage(props.currentBranding.brandLogo, setBrandLogoUrl);
    }
    if (props.currentBranding.navLogo != null) {
      void fetchImage(props.currentBranding.navLogo, setNavLogoUrl);
    }
  }, [props.currentBranding]);

  const brandMutation = useMutation(async () => {
    const response = await api.fetch(`${import.meta.env.VITE_API_URI}/v1/brands/self`, {
      method: 'PUT',
      body: JSON.stringify(brand),
      headers: {
        'Content-Type': 'application/json'
      }
    });

    if (response.ok) {
      const b = await WDBrandSchema.parseAsync(await response.json());
      setPageError(null);
      toast.success('Saved Brand Settings.');
      return b;
    } else {
      const e = await WDGenericResponseSchema.parseAsync(await response.json());
      toast.error(`Faild to save brand settings ${e.message}`);
      setPageError(e.message);
    }
  }, {
    onSuccess: (data) => {
      if (data != null) {
        queryClient.setQueryData(['brand', userContext.apparentUser.accountId], data);
      }
    }
  });

  const saveBrand = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    brandMutation.mutate();
  };

  const reset = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    setBrand(props.currentBranding);
    setBrandLogoUrl('');
    setNavLogoUrl('');
    setPageError(null);
    setBrandLogoError(null);
    setNavLogoError(null);
    if (props.currentBranding.brandLogo != null) {
      void fetchImage(props.currentBranding.brandLogo, setBrandLogoUrl);
    }
    if (props.currentBranding.navLogo != null) {
      void fetchImage(props.currentBranding.navLogo, setNavLogoUrl);
    }
    if (brandLogoFileRef.current != null) {
      brandLogoFileRef.current.value = '';
    }
    if (navLogoFileRef.current != null) {
      navLogoFileRef.current.value = '';
    }
  };

  const updateBrandField = (field: keyof WDBrandUpdate) => {
    return (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      setBrand((oldBrand) => {
        const brand = {
          ...oldBrand,
          [field]: event.target.value
        };
        return brand;
      });
    };
  };

  const convertBase64 = async (file: File): Promise<string | ArrayBuffer | null> => {
    return await new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const updateBrandFile = (field: keyof WDBrandUpdate) => {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      const asyncFunc = async (): Promise<void> => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const file = event.target.files![0];
        if (file.size > 256 * 1024) {
          const errorMessage = 'Max file size 256KB';
          switch (field) {
            case 'brandLogo':
              setBrandLogoError(errorMessage);
              break;
            case 'navLogo':
              setNavLogoError(errorMessage);
              break;
          }
          return;
        } else if (!ALLOWED_FILE_TYPES.includes(file.type)) {
          const errorMessage = 'Only PNG, WEBP, and SVG are supported.';
          switch (field) {
            case 'brandLogo':
              setBrandLogoError(errorMessage);
              break;
            case 'navLogo':
              setNavLogoError(errorMessage);
              break;
          }
          return;
        } else {
          switch (field) {
            case 'brandLogo':
              setBrandLogoError(null);
              break;
            case 'navLogo':
              setNavLogoError(null);
              break;
          }
        }

        const fileBase64 = await convertBase64(file);
        switch (field) {
          case 'brandLogo':
            setBrandLogoUrl(fileBase64?.toString() ?? '');
            break;
          case 'navLogo':
            setNavLogoUrl(fileBase64?.toString() ?? '');
            break;
        }
        setBrand((oldBrand) => {
          const brand = {
            ...oldBrand,
            [field]: file.name,
            [`${field}Raw`]: fileBase64
          };
          return brand;
        });
      };
      void asyncFunc();
    };
  };

  const previewTheme = (): void => {
    brandContext.setTheme(brand.themeName as ThemeName);
  };
  const resetTheme = (): void => {
    brandContext.setTheme(null);
  };

  const selectedTheme = Object.keys(THEMES).includes(brand.themeName) ? THEMES[brand.themeName as ThemeName] : THEMES.whitedog;

  const navPreviewStyle: React.CSSProperties = {
    backgroundColor: `#${selectedTheme.colors.nav}`,
    color: `#${selectedTheme.textColors.nav}`
  };

  return <>
    <div className='mx-1 rounded bg-warning text-warning-text py-2 px-3 mb-2'>
      <strong>Warning</strong> Changes may take up to 2-4 hours to take effect.
    </div>
    {pageError != null && <>
      <div className='mx-1 rounded bg-danger text-danger-text py-2 px-3 mb-2'>
        <strong>Error</strong> {pageError}
      </div>
    </>}
    <form>
      <table className='w-full table-auto'>
        <tbody>
          <tr>
            <td className='text-right'>Portal URL:</td>
            <td className='w-3/4'>
              <Input type='text' disabled value={brand.brandPath} />
            </td>
          </tr>
          <tr>
            <td className='text-right'>Brand Name:</td>
            <td className='w-3/4'>
              <Input type='text' onChange={updateBrandField('brandName')} value={brand.brandName} />
            </td>
          </tr>
          <tr>
            <td className='text-right'>Portal Name:</td>
            <td className='w-3/4'>
              <Input type='text' onChange={updateBrandField('appName')} value={brand.appName} />
            </td>
          </tr>
          <tr>
            <td className='text-right'>Theme:</td>
            <td className='w-3/4 flex'>
              <Select onChange={updateBrandField('themeName')} value={brand.themeName} className='w-full'>
                {Object.keys(THEMES).map((t) => <option key={t} value={t}>{THEMES[t as ThemeName].themeName}</option>)}
              </Select>
              <Button variant='secondary' type='button' onClick={previewTheme} title='Changes the theme for the current session.'>Preview</Button>
              <Button variant='secondary' type='button' onClick={resetTheme}>Reset</Button>
            </td>
          </tr>
          <tr>
            <td className='text-right'>Brand Logo:</td>
            <td className='w-3/4'>
              <Input type='file' onChange={updateBrandFile('brandLogo')} ref={brandLogoFileRef} />
              {brandLogoError != null && <small className='text-danger'>{brandLogoError}</small>}
            </td>
          </tr>
          <tr>
            <td></td>
            <td><small>Max size 256KB. SVG or PNG. We recommend you use a full color logo here.</small></td>
          </tr>
          <tr>
            <td className='text-right'>Brand Logo Preview:</td>
            <td className='w-3/4'>
              <img src={brandLogoUrl} className='h-14 border border-dark bg-light' alt={brand.brandName} />
            </td>
          </tr>
          <tr>
            <td className='text-right'>Nav Logo:</td>
            <td className='w-3/4'>
              <Input type='file' onChange={updateBrandFile('navLogo')} ref={navLogoFileRef} />
              {navLogoError != null && <small className='text-danger'>{navLogoError}</small>}
            </td>
          </tr>
          <tr>
            <td></td>
            <td><small>Max size 256KB. SVG or PNG. We recommend you use a single color logo in <span className='font-bold'>{selectedTheme.textColors.nav === 'ffffff' ? 'white' : 'black'}</span> here.</small></td>
          </tr>
          <tr>
            <td className='text-right'>Navbar Preview:</td>
            <td className='w-3/4'>
              <div className='h-nav w-full flex flex-row' style={navPreviewStyle}>
                <a className={'w-16 h-nav inline-block text-3xl p-1 text-center'}>
                  <i className='mdl2-2 mdl2-2-Waffle'></i>
                </a>
                <div className='my-auto'>
                  {brand.navLogo == null
                    ? brand.appName
                    : <img src={navLogoUrl} className='h-8' />}
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <RequirePermission permission={new Permission(PermissionVerb.Update, 'brand')}>
        <div className='flex pt-3'>
          <div className='mx-auto'>
            <Button variant='primary' type='submit' onClick={saveBrand}>Save</Button>
            <Button variant='secondary' type='reset' onClick={reset}>Reset</Button>
          </div>
        </div>
      </RequirePermission>
    </form>
  </>;
};

const BrandSettings: React.FC = () => {
  const api = useAPI();
  const userContext = useUserContext();
  const brandContext = useBrandContext();

  useTitle('Brand Settings');

  const getBrandTemplate = (): WDBrand => {
    const brand: WDBrand = {
      brandPath: brandContext.brandRef,
      brandName: userContext.apparentUser.accountName,
      appName: `${userContext.apparentUser.accountName} Portal`,
      themeName: 'whitedog',
      brandLogo: null,
      navLogo: null
    };

    return brand;
  };

  const brandDataQuery = useQuery(['brand', userContext.apparentUser.accountId], async () => {
    const response = await api.fetch(`${import.meta.env.VITE_API_URI}/v1/brands/self`, {
      method: 'GET'
    });

    if (response.ok) {
      const brand = await WDBrandSchema.parseAsync(await response.json());

      return brand;
    }

    return getBrandTemplate();
  });

  return <>
    <div className='flex pt-5'>
      <Frame varient='primary' title='Brand Settings' className='w-200 mx-auto'>

        {brandDataQuery.data != null
          ? <BrandSettingsForm currentBranding={brandDataQuery.data} />
          : <>
         <div className='flex w-full py-2'>
          <div className='mx-auto text-center'>
            <LoadingSpinner className='h-32 w-32' />
            <p>Loading...</p>
          </div>
        </div>
        </>}
      </Frame>
    </div>

  </>;
};

export default BrandSettings;
