import React from 'react';
import { Link } from 'react-router-dom';
import { z } from 'zod';

import { useQuery } from '@tanstack/react-query';

import {
  DualPaneLayout, LeftPane, LeftPaneHeader, RightPane
} from '../../components/DualPaneLayout';
import LoadingSpinner from '../../components/LoadingSpinner';
import { useAccountContext } from '../../contexts/AccountContext';
import { useAPI } from '../../libs/libAPI';
import useTitle from '../../libs/useTitle';
import { sortBy } from '../../libs/utils';
import { LGSystemSchema } from '../../types/LGSystem';

const Systems: React.FC = () => {
  const api = useAPI();
  const accountContext = useAccountContext();

  const systemsQuery = useQuery(['systems', accountContext.accountId], async () => {
    const response = await api.fetch(`${import.meta.env.VITE_API_URI}/v1/systems`, { method: 'GET' });

    const systems = await z.array(LGSystemSchema).parseAsync(await response.json());

    return systems;
  });

  useTitle('Systems');

  return <>
    <DualPaneLayout>
      <LeftPane>
        <LeftPaneHeader title='Systems' />
        <div>
          {systemsQuery.data?.sort(sortBy('name')).map((system) =>
            <Link key={system.id} to={`/systems/${system.id}`} title={`${system.name} [${system.inspectorAlias}]`}>
              <div className={'w-full rounded-md p-2 hover:bg-hover overflow-hidden overflow-ellipsis whitespace-nowrap'}>
                {system.name} [{system.inspectorAlias}]
              </div>
            </Link>
          )}
          {(systemsQuery.data?.length ?? -1) === 0 && <>
            <div className={'w-full rounded-md p-2 overflow-hidden overflow-ellipsis whitespace-nowrap'}>
              No Systems Found
            </div>
          </>}
          {systemsQuery.isLoading && <>
            <div className={'w-full rounded-md p-2 overflow-hidden overflow-ellipsis whitespace-nowrap'}>
              Loading...
            </div>
          </>}
        </div>
      </LeftPane>
      <RightPane>
        {systemsQuery.isFetching && <>
          <div className='flex flex-col mx-auto my-auto pt-12'>
            <LoadingSpinner className='mx-auto w-32 h-32' />
            <div className='text-center h2 pt-2'>Loading...</div>
          </div>
        </>}
      </RightPane>
    </DualPaneLayout>
  </>;
};

export default Systems;
