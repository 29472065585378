import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

// import * as Sentry from '@sentry/react';
import Apps from './Apps';
import AppDatasheet from './Apps/AppDatasheet';
import Callback from './Callback';
import Cases from './Cases';
import CaseDetails from './Cases/CaseDetails';
import Dashboard from './Dashboard';
import DevDashboard from './Dev/Dashboard';
import ProfilePage from './Dev/Profile';
import RequestDevPage from './Dev/Request';
import Embed from './Embed';
import GetCyberEmbed from './Embed/GetCyber';
import NotFound from './Error/NotFound';
import ErrorTest from './Error/Test';
import Loading from './Loading';
import Nodes from './Nodes';
import Node from './Nodes/Node';
import NodeSource from './Nodes/NodeSource';
import Settings from './Settings';
import BrandAppSettings from './Settings/BrandAppSettings';
import BrandSettings from './Settings/BrandSettings';
import Systems from './Systems';
import System from './Systems/System';
import SystemEntity from './Systems/SystemEntity';
import UserProfile from './UserProfile';

// const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);
const SentryRoutes = Routes; // TODO fix this

const PageRoutes: React.FC = () => {
  return <>
    <SentryRoutes>
      <Route path='/' element={<Dashboard />} />
      <Route path='/academy' element={<Navigate to='/e/academy' />} />
      <Route path='/apps' element={<Apps />}>
        <Route path=':product' element={<AppDatasheet />} />
      </Route>
      <Route path='/callback' element={<Callback />} />
      <Route path='/cases' element={<Cases />}>
        <Route path=':caseId' element={<CaseDetails />} />
      </Route>
      <Route path='/dev/dashboard' element={<DevDashboard />} />
      <Route path='/dev/request' element={<RequestDevPage />} />
      <Route path='/dev/profile' element={<ProfilePage />} />
      <Route path='/dev/loading' element={<Loading />} />
      <Route path='/dev/error' element={<ErrorTest />} />
      <Route path='/e/:site' element={<Embed />} />
      <Route path='/grc' element={<GetCyberEmbed />} />
      <Route path='/nodes' element={<Nodes />} />
      <Route path='/nodes/:nodeId/:tableName?' element={<NodeSource />}>
        <Route path=':entityId' element={<Node />} />
      </Route>
      <Route path='/profile' element={<UserProfile />} />
      <Route path='/settings' element={<Settings />}>
        <Route index element={<Navigate to='brand' />} />
        <Route path='brand' element={<BrandSettings />} />
        <Route path='brand_apps' element={<BrandAppSettings />} />
      </Route>
      <Route path='/systems' element={<Systems />} />
      <Route path='/systems/:systemId/:tableName?' element={<System />}>
        <Route path=':entityId' element={<SystemEntity />} />
      </Route>

      <Route path='*' element={<NotFound />} />
    </SentryRoutes>
  </>;
};

export default PageRoutes;
