import React from 'react';

import { WDTableWidgetConfiguration } from '../../types/WDDashboardWidget';
import WDTableData from '../../types/WDTableData';
import DynamicTable from '../DynamicTable';
import { DashboardWidgetProps } from './DashboardWidgetProps';

const TableWidget: React.FC<DashboardWidgetProps> = (props: DashboardWidgetProps) => {
  const config: WDTableWidgetConfiguration = props.def.configuration as WDTableWidgetConfiguration;

  if (props.def.data == null) {
    return <></>;
  }

  const tableData: WDTableData = {
    columns: config.columns,
    data: props.def.data,
    count: props.def.data.length
  };

  return <>
    <DynamicTable tableData={tableData} />
  </>;
};

export default TableWidget;
