import {
  CaseCategory, CaseOrigin, CasePriority, CaseSeverity, CaseState, CaseStatus, CaseType
} from '../types/CaseEnums';
import { UseApi } from './libAPI';

export interface CreateCase {
  title: string;
  description: string;
  priority: CasePriority;
  state: CaseState;
  status: CaseStatus;
  origin: CaseOrigin;
  category: CaseCategory;
  caseType: CaseType;
  severity: CaseSeverity;
}

export const createCase = async (api: UseApi, newCase: CreateCase): Promise<void> => {
  await api.fetch(`${import.meta.env.VITE_API_URI}/v1/cases`, {
    method: 'POST',
    body: JSON.stringify(newCase)
  });
};
