export const delay = async (interval: number): Promise<void> => {
  return await new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve();
    }, interval);
  });
};

export function isCallback<T>(maybeFunc: T | unknown): maybeFunc is T {
  return typeof maybeFunc === 'function';
}

export const sortBy = <T>(key: keyof T, reverse: boolean = false) => {
  return (a: T, b: T) => {
    if (a[key] === b[key]) {
      return 0;
    }
    return (a[key] < b[key] ? -1 : 1) * (reverse ? -1 : 1);
  };
};

export const enumKeyByValue = <TEnumKey extends string, TEnumVal extends string | number>(myEnum: { [key in TEnumKey]: TEnumVal }, enumValue: TEnumVal): string => {
  const keys = (Object.keys(myEnum) as TEnumKey[]).filter((x) => myEnum[x] === enumValue);
  return keys.length > 0 ? keys[0] : '';
};
