export interface App {
  name: string;
  category: string;
  description: string;
};

const APPS: App[] = [
  {
    name: 'APP',
    description: 'Advanced Phishing Protection',
    category: 'Email'
  },
  {
    name: 'ESS',
    description: 'Email Security Service',
    category: 'Email'
  },
  {
    name: 'PSA',
    description: 'Phishing & Security Awareness',
    category: 'Email'
  },
  {
    name: 'D3',
    description: 'Distributed DNS Defense',
    category: 'Endpoint'
  },
  {
    name: 'EDR',
    description: 'Endpoint Detection & Response',
    category: 'Endpoint'
  },
  {
    name: 'MDR',
    description: 'Managed Detection & Response',
    category: 'Endpoint'
  },
  // {
  //   name: 'XDR',
  //   description: 'eXtended Detection & Response',
  //   category: 'Endpoint'
  // },
  {
    name: 'NDR',
    description: 'Network Detection & Response',
    category: 'Network'
  },
  {
    name: 'NOC',
    description: 'Network Operations Center',
    category: 'Network'
  },
  {
    name: 'ZTNA',
    description: 'Zero Trust Network Access',
    category: 'Network'
  },
  // {
  //   name: 'ZTW',
  //   description: 'Zero Trust SD-WAN',
  //   category: 'Network'
  // },
  // {
  //   name: 'CSPM',
  //   description: 'Cloud Security Posture Management',
  //   category: 'Cloud'
  // },
  {
    name: 'DSB',
    description: 'Distributed SaaS Backup',
    category: 'Cloud'
  },
  {
    name: 'SOC',
    description: 'Security Operations Center',
    category: 'Cloud'
  },
  {
    name: 'SWG',
    description: 'Secure Web Gateway',
    category: 'Cloud'
  }
];

export default APPS;
