import React, { useState } from 'react';
import toast from 'react-hot-toast/headless';
import { useNavigate } from 'react-router-dom';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import Button from '../../components/Library/Button';
import Frame from '../../components/Library/Frame';
import Input from '../../components/Library/Input';
import LoadingSpinner from '../../components/LoadingSpinner';
import CenterLoadingSpinner from '../../components/LoadingSpinner/CenterLoadingSpinner';
import RequirePermission from '../../components/RequirePermission';
import { useAccountContext } from '../../contexts/AccountContext';
import { useUserContext } from '../../contexts/UserContext';
import APPS, { App } from '../../data/apps';
import { useAPI } from '../../libs/libAPI';
import { Permission, PermissionVerb } from '../../libs/libPermissions';
import { titleCase } from '../../libs/libString';
import useTitle from '../../libs/useTitle';
import { sortBy } from '../../libs/utils';
import { WDBrand, WDBrandSchema } from '../../types/WDBrand';
import { WDProductSchema } from '../../types/WDProduct';

interface BrandAppSettingsFormProps {
  currentBranding: WDBrand;
}

const BrandAppSettingsForm: React.FC<BrandAppSettingsFormProps> = (props) => {
  const api = useAPI();
  const queryClient = useQueryClient();
  const userContext = useUserContext();
  const accountContext = useAccountContext();

  const [apps, setApps] = useState<string[]>(props.currentBranding.apps ?? []);
  const [limitApps, setLimitApps] = useState<boolean>(apps.length !== 0);

  const updateBrandMutation = useMutation(async () => {
    const response = await api.fetch(`${import.meta.env.VITE_API_URI}/v1/brands/self`, {
      method: 'PATCH',
      body: JSON.stringify({
        apps
      })
    });

    const brand = await WDBrandSchema.parseAsync(await response.json());
    return brand;
  }, {
    onSuccess: (data) => {
      queryClient.setQueryData(['brand', userContext.apparentUser.accountId], data);
      toast.success('Saved app selection');
    },
    onError: () => {
      toast.error('Failed to save app selection');
    }
  });

  const appsQuery = useQuery(['settings', 'apps', accountContext.accountId], async () => {
    const response = await api.fetch(`${import.meta.env.VITE_API_URI}/v1/product-catalog/apps`, { method: 'GET' });
    const products = await WDProductSchema.array().parseAsync(await response.json());

    const catalog = products.sort(sortBy('name')).sort(sortBy('category')).filter((p) => p.showInCatalog) ?? [];

    const appsByCategory: { [key: string]: App[] } = catalog.reduce((r, a) => {
      if (!Object.keys(r).includes(a.category)) {
        r[a.category] = [];
      }
      r[a.category].push(a);
      return r;
    }, Object.create(null));

    return appsByCategory;
  });

  const isAppSelected = (appName: string): boolean => {
    if (!limitApps) {
      return true;
    }
    return apps.includes(appName);
  };

  const setAppLimit = (limit: boolean): void => {
    setLimitApps(limit);
    if (limit) {
      setApps(APPS.map((app) => app.name));
    } else {
      setApps([]);
    }
  };

  const toggleAppSelectedHandler = (app: string) => {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        if (!apps.includes(app)) {
          setApps([...apps, app]);
        }
      } else {
        if (apps.includes(app)) {
          setApps(apps.filter((a) => a !== app));
        }
      }
    };
  };

  const save = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    updateBrandMutation.mutate();
  };

  const reset = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setApps(props.currentBranding.apps ?? []);
    setLimitApps((props.currentBranding.apps ?? []).length !== 0);
  };

  if (appsQuery.data == null) return <CenterLoadingSpinner />;

  return <>
    <div className='mx-1 rounded bg-warning text-warning-text py-2 px-3 mb-2'>
      <strong>Warning</strong> Changes may take up to 2-4 hours to take effect.
    </div>
    <form>
      <table className='w-full table-auto'>
        <tbody>
          <tr>
            <td className='text-right'>Show:</td>
            <td className='w-3/4'>
              <div className='flex'>
                <div className='w-1/2 p-2'>
                  <div className={`w-full border border-background-darker flex flex-col py-3 cursor-pointer select-none rounded-lg ${limitApps ? '' : 'bg-selected border-selected-darker'} hover:bg-hover hover:border-hover-darker`} onClick={() => setAppLimit(false)}>
                    <div className='mx-auto'>All Apps</div>
                    <div className='mx-auto'>
                      <small>Shows all WhiteDog Services</small>
                    </div>
                  </div>
                </div>
                <div className='w-1/2 p-2'>
                  <div className={`w-full border border-background-darker flex flex-col py-3 cursor-pointer select-none rounded-lg ${limitApps ? 'bg-selected border-selected-darker' : ''} hover:bg-hover hover:border-hover-darker`} onClick={() => setAppLimit(true)}>
                    <div className='mx-auto'>Selected Apps</div>
                    <div className='mx-auto'>
                      <small>Shows selected WhiteDog Services</small>
                    </div>
                  </div>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td className='text-right'>Apps:</td>
            <td className='w-3/4'>
              <div className='pl-2'>
                {Object.keys(appsQuery.data).map((category) => (
                  <div key={category}>
                    {titleCase(category)}
                    <table>
                      <tbody>
                        {appsQuery.data[category].map((app) => (
                          <tr key={app.name}>
                            <td>&nbsp;</td>
                            <td>
                              <Input id={app.name} type='checkbox' className='' onChange={toggleAppSelectedHandler(app.name)} checked={isAppSelected(app.name)} disabled={!limitApps} />
                            </td>
                            <td className=''>
                              &nbsp;{app.name}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ))}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <RequirePermission permission={new Permission(PermissionVerb.Update, 'brand')}>
        <div className='flex pt-3'>
          <div className='mx-auto'>
            <Button variant='primary' type='submit' onClick={save}>Save</Button>
            <Button variant='secondary' type='reset' onClick={reset}>Reset</Button>
          </div>
        </div>
      </RequirePermission>
    </form>
  </>;
};

const BrandAppSettings: React.FC = () => {
  const api = useAPI();
  const navigate = useNavigate();
  const userContext = useUserContext();

  useTitle('Brand App Settings');

  const brandDataQuery = useQuery(['brand', userContext.apparentUser.accountId], async () => {
    const response = await api.fetch(`${import.meta.env.VITE_API_URI}/v1/brands/self`, {
      method: 'GET'
    });

    if (response.ok) {
      const brand = await WDBrandSchema.parseAsync(await response.json());

      return brand;
    }

    toast.error('You must setup your brand before you can change the displayed apps.');
    navigate('/settings/brand');
  });

  return <>
    <div className='flex pt-5'>
      <Frame varient='primary' title='Brand App Settings' className='w-200 mx-auto'>

        {brandDataQuery.data != null
          ? <BrandAppSettingsForm currentBranding={brandDataQuery.data} />
          : <>
         <div className='flex w-full py-2'>
          <div className='mx-auto text-center'>
            <LoadingSpinner className='h-32 w-32' />
            <p>Loading...</p>
          </div>
        </div>
        </>}
      </Frame>
    </div>

  </>;
};

export default BrandAppSettings;
