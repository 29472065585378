import React from 'react';

import { useBrandContext } from '../../contexts/BrandContext';

const NavLogo: React.FC = () => {
  const brandContext = useBrandContext();

  if (brandContext.brand.navLogo == null) {
    return <>{brandContext.brand.appName}</>;
  }

  // if (brandContext.brand.brandLogo.includes('.svg')) {
  //   return <>
  //   <svg height={'32px'}>
  //     <use href="http://localhost:5173/logos/wdc_dog_logo.svg" height={'32px'} />
  //   </svg>
  //   </>;
  // }

  return <><img className='h-8' src={`${import.meta.env.VITE_STATIC_URI}/brands/${brandContext.brand.brandPath}/${brandContext.brand.navLogo}`} /></>;
};

export default NavLogo;
