import { useCallback, useMemo } from 'react';

import { useParentQuery } from './useParentQuery';

export const useQueryState = (key: string, initialValue: string): [string, (state: string | ((old: string) => string)) => void] => {
  const [queryParams, setQueryParams] = useParentQuery();

  const state = useMemo(() => {
    return queryParams.get(key) ?? initialValue;
  }, [queryParams, key]);

  const setState = useCallback((value: string | ((old: string) => string)) => {
    setQueryParams((prev) => {
      const output = new URLSearchParams(prev);
      const newValue = typeof value === 'function' ? value(state) : value;
      output.set(key, newValue);
      return output;
    });
  }, [setQueryParams, key, state]);

  return [state, setState];
};

export const useQueryObjectState = <T>(key: string, initialValue: T): [T, (state: T | ((old: T) => T)) => void] => {
  const [stringState, setStringState] = useQueryState(key, JSON.stringify(initialValue));

  const state = useMemo(() => {
    try {
      return JSON.parse(stringState);
    } catch {
      return initialValue;
    }
  }, [stringState]);

  const setState = useCallback((value: T | ((old: T) => T)) => {
    const v: any = value; // ! This is stupid code to bypass incorrect typechecks
    const newValue = typeof v === 'function' ? v(state) : v;
    setStringState(JSON.stringify(newValue));
  }, [setStringState]);

  return [state, setState];
};
