import React from 'react';

import Frame from '../../components/Library/Frame';
import { useUserContext } from '../../contexts/UserContext';
import ProfileForm from './ProfileForm';

const Profile: React.FC = () => {
  const userContext = useUserContext();

  return (
    <>
      <div>
        <Frame varient='secondary' title='User Profile'>
         <ProfileForm profile={userContext.apparentUser} />
        </Frame>
      </div>
    </>
  );
};

export default Profile;
