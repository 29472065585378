import { z } from 'zod';

import { LGDataPrintViewSchema } from './LGDataPrint';
import { WDDashboardWidgetSchema } from './WDDashboardWidget';

export const WDNodeDetailsNodeSchema = z.object({
  label: z.string(),
  name: z.string(),
  systemId: z.number().int(),
  inspectorAlias: z.string(),
  defaultTable: z.string().nullable()
});

export const WDNodeDetailsSchema = z.object({
  view: LGDataPrintViewSchema.optional(),
  views: z.record(z.string(), z.array(z.array(z.array(z.any().nullable())).nullable())).optional(),
  charts: z.array(WDDashboardWidgetSchema),
  node: WDNodeDetailsNodeSchema
});

type WDNodeDetails = z.infer<typeof WDNodeDetailsSchema>;

export default WDNodeDetails;
