import React from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { ErrorBoundry } from '../../components/ErrorBoundry';
import { Modal, ModalBody, ModalHeader } from '../../components/Library/Modal';
import { Table, TableRow } from '../../components/Library/Table';
import { getDisplayValue } from './getDisplayValue';
import { useSystemOutletContext } from './SystemOutletContext';

const SystemEntity: React.FC = () => {
  const navigate = useNavigate();
  const [queryParams] = useSearchParams();
  const { entityId } = useParams();
  const { columns, data } = useSystemOutletContext();

  if (entityId == null || columns == null || data == null) {
    throw Error('Missing Data for System Entity');
  }

  const closeEntity = (): void => {
    navigate(`..${decodeURIComponent(queryParams.get('parentQuery') ?? '')}`);
  };

  const id = parseInt(entityId);

  const entityData = data[id];

  return <>
    <Modal show={true} modalSize='Large' closeModal={closeEntity}>
      <ModalHeader closeModal={closeEntity}>Details</ModalHeader>
      <ModalBody className='overflow-y-auto lg:max-h-[80vh]'>
        <ErrorBoundry key={entityId}>
          <ErrorBoundry.OK>
            <Table className='w-full table-fixed'>
              {columns.map((columnName, columnIndex) => (
                <TableRow key={columnName}>
                  <th className='w-1/3 pr-1 text-right'>{columnName}</th>
                  <td className='w-2/3 pl-1'>{getDisplayValue(entityData[columnIndex])}</td>
                </TableRow>
              ))}
            </Table>
          </ErrorBoundry.OK>
          <ErrorBoundry.SimpleError>
            We had a problem rendering the details for the row you selected.
          </ErrorBoundry.SimpleError>
        </ErrorBoundry>
      </ModalBody>
    </Modal>

  </>;
};

export default SystemEntity;
