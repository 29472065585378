import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { UseQueryResult } from '@tanstack/react-query';
import {
  createColumnHelper, flexRender, getCoreRowModel, getSortedRowModel, SortingState, useReactTable
} from '@tanstack/react-table';

import { getIconForSortDirection } from '../../components/Library/getIconForSortDirection';
import LoadingSpinner from '../../components/LoadingSpinner';
import { formatToLocalTime, humanizedTimeSpan, toLocalTime } from '../../libs/libDate';
import { enumKeyByValue } from '../../libs/utils';
import { CaseSeverity, CaseStatus } from '../../types/CaseEnums';
import WDCase from '../../types/WDCase';
import { useCasesQuery } from './';

interface CaseListProps {
  caseQuery: UseQueryResult<WDCase[], unknown>;
};

const columnHelper = createColumnHelper<WDCase>();

interface CaseLinkProps {
  caseId: string;
  subject: string | null;
}

const CaseLink: React.FC<CaseLinkProps> = (props: CaseLinkProps) => {
  const { search } = useLocation();
  return <Link to={`${props.caseId}?casesQuery=${encodeURIComponent(search)}`} className='overflow-hidden overflow-ellipsis whitespace-nowrap underline'>{props.subject != null ? props.subject : <i>Missing Subject</i>}</Link>;
};

const columns = [
  columnHelper.accessor('title', {
    header: () => 'Subject',
    cell: info => <CaseLink caseId={info.row.original.id} subject={info.getValue()} />
  }),
  columnHelper.accessor('status', {
    header: () => 'Status',
    cell: info => enumKeyByValue(CaseStatus, info.getValue())
  }),
  columnHelper.accessor('createdByName', {
    header: () => 'Created By',
    cell: info => info.getValue()
  }),
  columnHelper.accessor('severity', {
    header: () => 'Severity',
    cell: info => enumKeyByValue(CaseSeverity, info.getValue())
  }),
  columnHelper.accessor('createdAt', {
    header: () => 'Created',
    cell: info => <span title={formatToLocalTime(info.getValue())}>{humanizedTimeSpan(toLocalTime(info.getValue()))}</span>,
    invertSorting: true
  }),
  columnHelper.accessor('modifiedAt', {
    header: () => 'Modified',
    cell: info => <span title={formatToLocalTime(info.getValue())}>{humanizedTimeSpan(toLocalTime(info.getValue()))}</span>,
    invertSorting: true
  })
];

const DEFAULT_SORT = [{ desc: false, id: 'modifiedAt' }];

const getInitialSortState = (search: URLSearchParams): SortingState => {
  try {
    const sort = search.get('sort');
    if (sort != null) {
      const sortState = JSON.parse(decodeURIComponent(sort));
      return sortState;
    }
  } catch {
    console.error('Failed to parse existing sort state. Resetting to default');
  }
  return DEFAULT_SORT;
};

const getString = (value: unknown): string | undefined => {
  if (typeof value === 'string') {
    return value;
  }
};

const CaseList: React.FC<CaseListProps> = (props: CaseListProps) => {
  const [search, setSearch] = useCasesQuery();
  const [sorting, setSorting] = useState<SortingState>(getInitialSortState(search));

  const setSortState = (state: React.SetStateAction<SortingState>): void => {
    let newState: SortingState;
    if (typeof state === 'function') {
      newState = state(sorting);
    } else {
      newState = state;
    }

    setSorting(newState);
    setSearch((prev) => {
      const output = new URLSearchParams(prev);

      output.set('sort', JSON.stringify(newState));

      return output;
    });
  };

  const table = useReactTable({
    data: props.caseQuery.data ?? [],
    state: {
      sorting
    },
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSortState
  });

  if (props.caseQuery.isLoading) {
    return <>
      <div className='flex flex-col mx-auto my-auto pt-12'>
        <LoadingSpinner className='mx-auto w-32 h-32' />
        <div className='text-center h2 pt-2'>Loading...</div>
      </div>
    </>;
  }

  if (props.caseQuery.isError) {
    return <>
      A problem occured loading the cases for this account.
    </>;
  }

  return <>
    <table className='w-full max-w-full md:w-[calc(100vw-416px)] mx-8 md:mx-12 overflow-hidden table-fixed'>
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id} className='border-b border-b-background3-dark'>
            {headerGroup.headers.map((header) => (
              <th key={header.id} className={`py-3 text-left px-2 w-col-${header.column.id} ${header.column.id === 'subject' ? 'table-cell' : 'hidden md:table-cell'}`}>
                {header.isPlaceholder
                  ? null
                  : <div onClick={header.column.getToggleSortingHandler()} className={`flex ${header.column.getCanSort() ? 'cursor-pointer select-none' : ''}`}>
                    <div className='inline-block'>{flexRender(header.column.columnDef.header, header.getContext())}</div>
                    <div className='inline-block ml-auto'>{getIconForSortDirection(header.column.getIsSorted())}</div>
                  </div>}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map(row => (
          <tr key={row.id} className='border-b border-b-background3-dark hover:bg-hover'>
            {row.getVisibleCells().map(cell => {
              const overflowClasses = 'overflow-hidden overflow-ellipsis';
              const useOverflow = cell.column.id === 'subject';
              return (
                <td key={cell.id} className={`p-2 whitespace-nowrap w-col-${cell.column.id} ${overflowClasses} ${useOverflow ? 'table-cell' : 'hidden md:table-cell'}`} title={getString(cell.getValue())}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              );
            })}
          </tr>
        ))}
        {table.getRowModel().rows.length === 0 && <>
          <tr className='border-b border-b-background3-dark'>
            <td colSpan={columns.length} className='p-2'>
              There are no cases to show.
            </td>
          </tr>
        </>}
      </tbody>
    </table>
  </>;
};

export default CaseList;
