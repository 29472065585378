import React, { useEffect, useState } from 'react';

import { IdToken, useAuth0 } from '@auth0/auth0-react';

import useTitle from '../../../libs/useTitle';

const ProfilePage: React.FC = () => {
  const { user, isAuthenticated, isLoading, getIdTokenClaims } = useAuth0();

  useTitle(`${user?.name ?? ''} Profile`);

  const [claims, setClaims] = useState<IdToken | undefined>();

  useEffect(() => {
    const getIdTokenClaimsAsync = async (): Promise<void> => {
      const claims = await getIdTokenClaims();
      setClaims(claims);
    };
    void getIdTokenClaimsAsync();
  }, []);

  if (isLoading) return <div>Loading...</div>;

  return isAuthenticated
    ? (
        <div className='p-3'>
          <h2>User:</h2>
          <pre className='overflow-x-auto'>
            {JSON.stringify(user, null, 2)}
          </pre>
          <h2>Claims:</h2>
          <pre className='overflow-x-auto'>
            {JSON.stringify(claims, null, 2)}
          </pre>
        </div>
      )
    : <></>;
};

export default ProfilePage;
