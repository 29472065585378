import React from 'react';

import Frame from '../../components/Library/Frame';
import Select from '../../components/Library/Select';
import { useThemeModePreference } from '../../libs/usePreference';
import ThemeType from '../../types/ThemeType';

const ThemeModePreference: React.FC = () => {
  const [themeMode, setThemeMode] = useThemeModePreference();

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    setThemeMode(parseInt(event.target.value));
  };

  return <tr>
    <td className='text-right'>Theme Mode:</td>
    <td className='w-3/4'>
      <Select value={themeMode} onChange={handleChange} className='w-full'>
        <option value={ThemeType.Dynamic}>Dynamic</option>
        <option value={ThemeType.Light}>Light</option>
        <option value={ThemeType.Dark}>Dark</option>
      </Select>
    </td>
  </tr>;
};

const Preferences: React.FC = () => {
  return <>
    <Frame varient='secondary' title='Preferences' className='mt-6'>
      <table className='w-full table-auto'>
        <tbody>
          <ThemeModePreference />
        </tbody>
      </table>
    </Frame>
  </>;
};

export default Preferences;
