import { addDays, format } from 'date-fns';
import React from 'react';
import { NavigateOptions } from 'react-router';
import { Link, Outlet, URLSearchParamsInit, useSearchParams } from 'react-router-dom';
import { z } from 'zod';

import { captureException } from '@sentry/react';
import { useQuery } from '@tanstack/react-query';

import {
  DualPaneLayout, LeftPane, LeftPaneHeader, RightPane, RightPaneHeader
} from '../../components/DualPaneLayout';
import RequirePermission from '../../components/RequirePermission';
import { useAccountContext } from '../../contexts/AccountContext';
import { useUserContext } from '../../contexts/UserContext';
import { useAPI } from '../../libs/libAPI';
import { Permission, PermissionContext, PermissionVerb } from '../../libs/libPermissions';
import useTitle from '../../libs/useTitle';
import { WDCaseSchema } from '../../types/WDCase';
import CaseList from './CaseList';

interface CaseNavLink {
  path: string;
  icon: string;
  name: string;
};

const caseNavLinks: CaseNavLink[] = [
  {
    name: 'All Open',
    path: 'open',
    icon: 'bi-inboxes-fill'
  },
  {
    name: 'My Open',
    path: 'my',
    icon: 'bi-inbox-fill'
  },
  {
    name: 'Recently Closed',
    path: 'closed',
    icon: 'bi-archive-fill'
  }
];

interface NavItemProps {
  name: string;
  path: string;
  icon: string;
  currentCasePath: string;
}

const NavItem: React.FC<NavItemProps> = (props) => {
  return (
    <Link key={props.name} to={`/cases?status=${props.path}`}>
      <div className={`w-full text-lg rounded-md p-2 hover:bg-hover ${props.path === props.currentCasePath ? 'bg-selected' : ''}`}>
        <i className={`bi ${props.icon}`} /> {props.name}
      </div>
    </Link>
  );
};

declare type SetURLSearchParams = (nextInit?: URLSearchParamsInit | ((prev: URLSearchParams) => URLSearchParamsInit), navigateOpts?: NavigateOptions) => void;

export const useCasesQuery = (): [URLSearchParams, SetURLSearchParams] => {
  const [searchParams, setSearchParams] = useSearchParams();
  if (searchParams.has('casesQuery')) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const decodedSearch = decodeURIComponent(searchParams.get('casesQuery')!);
    return [new URLSearchParams(decodedSearch), () => { throw Error('Unsupported Set on Child Page'); }];
  }

  return [searchParams, setSearchParams];
};

const Cases: React.FC = () => {
  const [searchParams] = useCasesQuery();
  const userContext = useUserContext();
  const accountContext = useAccountContext();
  const api = useAPI();

  const caseStatus = searchParams.get('status') ?? (userContext.hasPermission(new Permission(PermissionVerb.Read, 'case', PermissionContext.Account)) ? 'open' : 'my');

  const statusQueryMap: { [name: string]: string } = {
    open: 'eq(state,active)',
    my: `eq(state,active)&eq(createdById,${userContext.apparentUser.id})`,
    closed: `eq(state,resolved)&gt(modifiedAt,${format(addDays(new Date(), -30), 'yyyy-MM-dd')})`
  };

  useTitle('Cases');

  const cases = useQuery(['cases', caseStatus, accountContext.accountId], async () => {
    const response = await api.fetch(`${import.meta.env.VITE_API_URI}/v1/cases?q=${encodeURIComponent(statusQueryMap[caseStatus])}`, { method: 'GET' });
    const casesList = await z.array(WDCaseSchema).parseAsync(await response.json());

    return casesList;
  });

  if (cases.isError) {
    captureException(cases.error);
  }

  return <>
    <DualPaneLayout>
      <LeftPane>
        <LeftPaneHeader title='Cases' />
        <div>
          <RequirePermission permission={new Permission(PermissionVerb.Read, 'case', PermissionContext.Account)}>
            <NavItem name='All Open' path='open' icon='bi-inboxes-fill' currentCasePath={caseStatus} />
          </RequirePermission>
          <NavItem name='My Open' path='my' icon='bi-inbox-fill' currentCasePath={caseStatus} />
          <NavItem name='Recently Closed' path='closed' icon='bi-archive-fill' currentCasePath={caseStatus} />
        </div>
      </LeftPane>
      <RightPane>
        <RightPaneHeader title={caseNavLinks.find((caseNavLink) => caseNavLink.path === caseStatus)?.name} />
        <CaseList caseQuery={cases} />
      </RightPane>
      <Outlet />
    </DualPaneLayout>
  </>;
};

export default Cases;
