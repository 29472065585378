import React from 'react';
import ReactJson from 'react-json-view';

import { formatToLocalTime } from '../../libs/libDate';

export const ISO1806_TIMESTAMP_REGEX = /^[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}(.[0-9]+)?Z$/;
export const HTTP_REGEX = /^https?:\/\//;

export const getDisplayValue = (value: any): React.ReactNode => {
  try {
    if (value == null) {
      return <><i>null</i></>;
    }
    if (typeof value === 'boolean') {
      return <>{value ? 'true' : 'false'}</>;
    }
    if (typeof value === 'string' && ISO1806_TIMESTAMP_REGEX.test(value)) {
      return <span title={value}>{formatToLocalTime(value)}</span>;
    }
    if (typeof value === 'string' && HTTP_REGEX.test(value)) {
      return <a href={value} target='_blank' rel="noreferrer" className='underline'>{value}</a>;
    }
    if (typeof value === 'object' && Array.isArray(value)) {
      return value.map((v, idx) => <React.Fragment key={idx}>{getDisplayValue(v)}, </React.Fragment>);
    }
    if (typeof value === 'object') {
      return <ReactJson src={value} theme='google' collapsed={true} />; // TODO We need to decide how we are going to display object values in this data.
    }

    return <>{value}</>;
  } catch {
    console.error('Error formatting:', value);
    return <i>Error formatting value</i>;
  }
};
