import { z } from 'zod';

import { GenericDataListSchema } from './GenericDataList';
import { WDTableDataColumnSchema } from './WDTableData';

export enum WDWidgetType {
  Pie = 'pie',
  Bar = 'bar',
  Table = 'table',
  Scoreboard = 'scoreboard',
  SimpleTable = 'simple_table'
}

const WDGenericChartWidgetConfigurationSchema = z.object({
  showTooltip: z.oboolean(), // Defaults to true
  showLegend: z.oboolean() // Defaults to false
});

export const WDPieChartWidgetConfigurationSchema = z.intersection(WDGenericChartWidgetConfigurationSchema, z.object({
  pies: z.array(z.object({
    showSliceLabels: z.oboolean(), // Defaults to true
    data: z.array(z.object({
      label: z.string(),
      value: z.number(),
      color: z.ostring()
    }))
  }))
}));

export const WDBarChartWidgetConfigurationSchema = z.intersection(WDGenericChartWidgetConfigurationSchema, z.object({
  xAxisLabelKey: z.ostring(),
  yAxisLabelKey: z.ostring(),
  showGrid: z.oboolean(), // Defaults to true
  useUniformBarColor: z.oboolean(), // defaults to true
  bars: z.array(z.object({
    dataKey: z.string(),
    label: z.ostring(),
    colorKey: z.ostring()
  }))
}));

export const WDTableWidgetConfigurationSchema = z.object({
  columns: z.array(WDTableDataColumnSchema)
});

export type WDPieChartWidgetConfiguration = z.infer<typeof WDPieChartWidgetConfigurationSchema>;
export type WDBarChartWidgetConfiguration = z.infer<typeof WDBarChartWidgetConfigurationSchema>;
export type WDTableWidgetConfiguration = z.infer<typeof WDTableWidgetConfigurationSchema>;

export const WDWidgetConfigurationSchema = z.union([WDPieChartWidgetConfigurationSchema, WDBarChartWidgetConfigurationSchema, WDTableWidgetConfigurationSchema]);

export type WDWidgetConfiguration = z.infer<typeof WDWidgetConfigurationSchema>;

export const WDDashboardWidgetSchema = z.object({
  label: z.string(),
  type: z.nativeEnum(WDWidgetType),
  data: GenericDataListSchema.optional(),
  configuration: WDWidgetConfigurationSchema
});

type WDDashboardWidget = z.infer<typeof WDDashboardWidgetSchema>;

export default WDDashboardWidget;
