import React from 'react';

import Input from '../../components/Library/Input';
import WDUser from '../../types/WDUser';

interface ProfileFormProps {
  profile: WDUser;
}

const ProfileForm: React.FC<ProfileFormProps> = (props) => {
  return (
    <>
      <form>
        <table className='w-full table-auto'>
          <tbody>
            <tr>
              <td className='text-right'>First Name:</td>
              <td className='w-3/4'>
                <Input type='text' disabled value={props.profile.firstName} />
              </td>
            </tr>
            <tr>
              <td className='text-right'>Last Name:</td>
              <td className='w-3/4'>
                <Input type='text' disabled value={props.profile.lastName} />
              </td>
            </tr>
            <tr>
              <td className='text-right'>Company:</td>
              <td className='w-3/4'>
                <Input type='text' disabled value={props.profile.accountName} />
              </td>
            </tr>
            <tr>
              <td className='text-right'>Email:</td>
              <td className='w-3/4'>
                <Input type='text' disabled value={props.profile.email} />
              </td>
            </tr>
            <tr>
              <td className='text-right'>Office Phone:</td>
              <td className='w-3/4'>
                <Input type='text' disabled value={props.profile.officePhone ?? ''} placeholder='null' />
              </td>
            </tr>
            <tr>
              <td className='text-right'>Mobile Phone:</td>
              <td className='w-3/4'>
                <Input type='text' disabled value={props.profile.mobilePhone ?? ''} placeholder='null' />
              </td>
            </tr>
            <tr>
              <td className='text-right'>Service Provider:</td>
              <td className='w-3/4'>
                <Input type='text' disabled value={props.profile.serviceProviderName ?? ''} placeholder='None' />
              </td>
            </tr>
            <tr>
              <td className='text-right'>Portal Role:</td>
              <td className='w-3/4'>
                <Input type='text' disabled value={props.profile.portalRole} />
              </td>
            </tr>

          </tbody>
        </table>
      </form>
    </>
  );
};

export default ProfileForm;
