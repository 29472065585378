import { z } from 'zod';

export const WDCaseLogEntrySchema = z.object({
  id: z.string(),
  subject: z.string(),
  fromName: z.string(),
  description: z.string(),
  htmlDescription: z.string(),
  // activityType: z.string(),
  // status: z.string(),
  createdByName: z.string(),
  createdAt: z.string(),
  modifiedByName: z.string(),
  modifiedAt: z.string()
});

type WDCaseLogEntry = z.infer<typeof WDCaseLogEntrySchema>;

export default WDCaseLogEntry;
