import context from 'react-bootstrap/esm/AccordionContext';

import { useAccountContext } from '../../contexts/AccountContext';
import { IUserContext } from '../../contexts/UserContext';
import { Permission, PermissionVerb } from '../../libs/libPermissions';

interface QuickNavItem {
  icon: string;
  name: string;
  path?: string;
  to?: string;
  auth?: (userContext: IUserContext) => boolean; // TODO refactor to remove this as a func and make it just a bool
  exact?: boolean;
  className?: string;
  disabled?: boolean;
}

export const useNavItems = (): QuickNavItem[] => {
  const accountContext = useAccountContext();

  const navItems: QuickNavItem[] = [
    {
      name: 'Home',
      icon: 'bi-house',
      path: '/',
      exact: true
    },
    {
      name: 'Apps',
      icon: 'bi-grid',
      path: '/apps'
    },
    {
      name: 'Cases',
      icon: 'bi-inboxes',
      path: '/cases'
    },
    {
      name: 'GRC',
      icon: 'bi-eye',
      path: '/grc',
      auth: (context) => context.apparentUser.accountIsServiceProvider && import.meta.env.VITE_ENV_BANNER_LABEL !== 'Prod'
    },
    {
      name: 'Nodes',
      icon: 'bi-laptop',
      path: '/nodes',
      auth: (context) => context.hasPermission(new Permission(PermissionVerb.Read, 'nodes'))
    },
    {
      name: 'Systems',
      icon: 'bi-collection',
      path: '/systems',
      auth: (context) => context.hasPermission(new Permission(PermissionVerb.Read, 'systems'))
    },
    {
      name: '',
      icon: '',
      className: 'mt-auto'
    },
    {
      name: 'Academy',
      icon: 'bi-easel',
      path: '/e/academy',
      auth: (context) => context.apparentUser.accountIsServiceProvider
    },
    {
      name: 'Partner',
      icon: 'bi-people',
      to: '/partner/',
      auth: (context) => context.hasPermission(new Permission(PermissionVerb.Read, 'partner_site')) && context.apparentUser.accountIsServiceProvider
    },
    {
      name: 'Admin',
      icon: 'bi-radioactive',
      to: '/admin/',
      auth: (context) => context.hasPermission(new Permission(PermissionVerb.Read, 'admin_site'))
    },
    {
      name: 'Settings',
      icon: 'bi-gear',
      path: '/settings',
      auth: (context) => context.hasPermission(new Permission(PermissionVerb.Read, 'settings')) && context.apparentUser.accountIsServiceProvider
    },
    {
      name: 'Developer',
      icon: 'bi-person-badge',
      path: '/dev',
      auth: (context) => context.hasPermission(new Permission(PermissionVerb.Read, 'developer'))
    }
  ];

  return navItems;
};
