import React from 'react';

import { VisibilityState } from '@tanstack/react-table';

import { usePreferenceState } from './usePreference';

export const useVisibleColumns = (localStorageKey: string, initialState: VisibilityState = {}): [VisibilityState, (state: React.SetStateAction<VisibilityState>) => void] => {
  const storageKey = `visibleColumns-${localStorageKey.replace('.', '_')}`;
  const [state, setState] = usePreferenceState<VisibilityState>(storageKey, initialState);

  const set = (s: React.SetStateAction<VisibilityState>): void => {
    let newState: VisibilityState;
    if (typeof s === 'function') {
      newState = s(state);
    } else {
      newState = s;
    }

    setState(newState);
  };

  return [state, set];
};
